export class Category {
  title: string;
  isExpanded: boolean = false;
  faqs: Faqs[] = [];

  constructor(input: any = {}) {
    this.title = input.title;
    input.faqs?.forEach(q => {
      let faq: Faqs = new Faqs(q);
      this.faqs.push(faq);
    });
  }
}

export class Faqs {
  name: string;
  body: string;

  constructor(input: any = {}) {
    this.name = input.name;
    this.body = input.body;
  }
}
