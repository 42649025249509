import {Injectable} from '@angular/core';
import {User} from '../models/user.model';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpService} from './http.service';
import {Card} from '../models/card.model';
import {Iban} from '../models/iban.model';
import {Wallet} from '../models/wallet';
import {Avatar} from 'src/app/shared/models/avatar.model';
import {BankName} from '../models/bank-name.model';
// @ts-ignore
import {bankList} from '../json/bank.json';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userStream = new BehaviorSubject<User>(new User());

  constructor(private http: HttpService) {
  }

  // checkUserVerification(user: User): boolean {
  //   return (user.bankVerified === 'ACCEPTED' &&
  //     user.emailVerified === 'ACCEPTED' &&
  //     user.addressVerified === 'ACCEPTED' &&
  //     user.phoneVerified === 'ACCEPTED' &&
  //     user.identityCardVerified === 'ACCEPTED' && user.landlinePhoneVerified === 'ACCEPTED' && user.identityVerified === 'ACCEPTED');
  // }

  getBankName(bankDetails: BankName): string {
    let bankName = '';
    switch (bankDetails.type) {
      case 'card': {
        const prefix = bankDetails.value.substring(0, 6);
        for (const item of bankList) {
          if (item.card.includes(prefix)) {
            bankName = item.name;
          }
        }
        break;
      }
      case 'sheba': {
        console.log('bankDetails', bankDetails);
        const shebaPrefix = bankDetails.value.substring(2, 5);
        console.log('shebaPrefix', shebaPrefix);
        for (const item of bankList) {
          if (shebaPrefix === item.sheba) {
            bankName = item.name;
          }
        }
        break;
      }
      default:
        return '';
    }
    return bankName;
  }

  getUser(): Observable<User> {
    return this.http.request('get', '/v1/user').pipe(
      map((response: any) => response.data),
      map(data => new User(data))
    );
  }

  getCard(): Observable<Card[]> {
    return this.http.request('get', '/v1/user/credit-card').pipe(
      map((response: any) => response.data),
      map((cards: any[]) => cards.map(card => new Card(card)))
    );
  }

  getIban(): Observable<Iban[]> {
    return this.http.request('get', '/v1/user/iban').pipe(
      map((response: any) => response.data),
      map((ibans: any[]) => ibans.map(iban => new Iban(iban)))
    );
  }

  saveCard(card: string): Observable<any> {
    return this.http.request('post', '/v1/user/credit-card', {card});
  }

  saveIban(iban: Iban): Observable<any> {
    return this.http.request('post', '/v1/user/iban', {iban: iban.iban});
  }

  getWallet(): Observable<any> {
    return this.http.request('get', '/v1/user/wallet').pipe(
      map((response: any) => response.data),
      map((wallets: any[]) => wallets.map(wallet => new Wallet(wallet)))
    );
  }

  createWallet(wallet: Wallet): Observable<any> {
    return this.http.request('post', '/v1/user/wallet', {
      currencyId: wallet.currencyId,
      wallet: wallet.wallet,
      name: wallet.name,
      tag: wallet.tag
    });
  }

  removeWallet(id: number): Observable<any> {
    return this.http.request('delete', `/v1/user/wallet/${id}`);
  }

  removeCard(id: number): Observable<any> {
    return this.http.request('delete', `/v1/user/credit-card/${id}`);
  }

  removeIban(id: number): Observable<any> {
    return this.http.request('delete', `/v1/user/iban/${id}`);
  }

  verification(nationalIdImage: string, selfieImage: string): Observable<any> {
    return this.http.request('post', '/v1/user/authentication', {
      nationalIdImage,
      selfieImage
    });
  }

  getAvatarList(): Observable<Avatar[]> {
    return this.http.request('get', '/v1/avatar').pipe(
      map((response: any) => response.data),
    );
  }

  saveAvatar(userData: User): Observable<any> {
    return this.http.request('put', '/v1/user', {
      first_name: userData.firstName,
      last_name: userData.lastName,
      father_name: userData.fatherName,
      birthday: userData.birthday,
      avatar_id: userData.avatar.id,
      phone: userData.phone,
      password: userData.password,
    });
  }
}


