import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {OrderDetail} from 'src/app/shared/models/order-detail.model';
import {OrderService} from 'src/app/shared/services/order.service';
import {Order} from 'src/app/shared/models/order.model';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  isSignedIn = this.authenticationService.checkUserSignedIn();
  orders: OrderDetail[];
  @Output() showOrder = new EventEmitter<OrderDetail>();

  constructor(private authenticationService: AuthenticationService, private orderService: OrderService) {
  }

  openOrder(order: OrderDetail): void {
    this.showOrder.emit(order);
  }

  getOrders(): void {
    if (this.isSignedIn) {
      this.orderService.getOrder().subscribe((orders: OrderDetail[]) => {
        this.orders = orders.reverse().filter(order => order.status === 'PENDING' || order.status === 'FINISHED' || order.status === 'USER_VERIFICATION').filter((order, index) => index < 3);
      });
    }

  }

  ngOnInit(): void {
    this.getOrders();
  }


}
