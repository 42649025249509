<div class="d-flex justify-content-between">
  <app-sidebar></app-sidebar>
  <div class="content  m-t-c">
    <div class="container-fluid">
      <p class="me-3 fs-20 font-weight-heavy">لیست سفارشات من</p>
      <div class="card px-0">
        <div class="card-body px-20 position-relative">
          <ng-container *ngIf="orders;else loadingSkeleton">

            <div *ngFor="let order of orders" class="row-data py-3 mb-3">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-6 col-md-3 col-xxl-2 d-flex align-items-center justify-content-start me-xxl-4">
                    <img class="coin-icon" [src]="order.currency.iconPath" alt="coin">
                    <p class="mb-0 fs-18">{{order.currency.name}} <span class="fs-14 font-weight-light me-1">
                  ({{order.currency.id}})</span></p>
                  </div>
                  <div class="col-6 col-md d-flex align-items-center justify-content-center text-nowrap">
                    <p class="mb-0 fs-18 font-weight-bold">{{order.order?.amount | number:'1.0-0' | formatNumber: 'toPersian'}}
                      <span class="fs-14 font-weight-light me-1">عدد</span></p>
                  </div>
                  <div class="col-6 col-md d-flex align-items-center justify-content-center mt-2 mt-md-0">
                    <p
                      class="mb-0 fs-14 font-weight-light">{{order.createdAt | dateFormat:'jYYYY/jMM/jDD' | formatNumber: 'toPersian'}}
                    </p>
                  </div>
                  <div class="col-6 col-md d-flex align-items-center justify-content-center mt-2 mt-md-0">
                    <p class="mb-0 fs-18">{{order.order?.price/10 | number:'1.0-0' | formatNumber: 'toPersian'}}<span
                      class="fs-14 font-weight-light me-2">تومان</span>
                    </p>
                  </div>
                  <div class="col-12 col-md-3 d-flex align-items-center justify-content-center mt-2 mt-md-0">
                    <ng-container *ngIf="order.status === 'PENDING'">
                      <div class="pending-status">
                        <p *ngIf="order.type === 'sell'" class="mb-0 fs-12 font-weight-bold">
                          فروش درحال بررسی</p>
                        <p *ngIf="order.type === 'buy'" class="mb-0 fs-12 font-weight-bold">
                          خرید درحال بررسی</p>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="order.status === 'FINISHED'">
                      <div [ngClass]="order.type === 'sell' ? 'completed-sell-status': 'completed-buy-status'">
                        <p *ngIf="order.type === 'sell'" class="mb-0 fs-12 font-weight-bold">
                          فروخته شده</p>
                        <p *ngIf="order.type === 'buy'" class="mb-0 fs-12 font-weight-bold">
                          خریده شده</p>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="order.status === 'FAILED'">
                      <div class="completed-sell-status">
                        <p class="mb-0 fs-12 font-weight-bold">ناموفق</p>
                      </div>

                    </ng-container>
                  </div>
                  <div (click)="showModal(order)"
                       class="col-2 col-md-1 d-flex align-items-center justify-content-center cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path
                        d="M14.34,0H5.67C2.28,0,0,2.38,0,5.92v8.17C0,17.62,2.28,20,5.67,20h8.67C17.73,20,20,17.62,20,14.09V5.92C20,2.38,17.73,0,14.34,0"
                        transform="translate(2 2)" fill="#fff" opacity="0.4"/>
                      <path
                        d="M8.959,1.2a1.2,1.2,0,1,1,1.2,1.2A1.2,1.2,0,0,1,8.959,1.2Zm-4.479,0a1.2,1.2,0,1,1,1.2,1.2A1.2,1.2,0,0,1,4.479,1.2ZM0,1.2a1.2,1.2,0,1,1,1.2,1.2A1.2,1.2,0,0,1,0,1.2Z"
                        transform="translate(6.323 10.804)" fill="#fff"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center pt-3 row-data" *ngIf="orders.length < 1">
              <p class="fs-18 font-weight-bold">هیچ سفارشی ثبت نشده است.</p>
            </div>
          </ng-container>
          <ng-template #loadingSkeleton>
            <div *ngFor="let _ of skeleton" class="row-data py-3 mb-3">
              <div class="container-fluid">
                <div class="row w-100">
                  <div class="col-3 col-xxl-2 d-flex align-items-center justify-content-center pe-3">
                    <ngx-skeleton-loader
                      class="order-loading w-100 me-3"
                      animation="progress-dark"
                      count="1" appearance="line"></ngx-skeleton-loader>
                  </div>
                  <div class="col d-flex align-items-center justify-content-center">
                    <ngx-skeleton-loader
                      class="order-loading w-100"
                      animation="progress-dark"
                      count="1" appearance="line"></ngx-skeleton-loader>
                  </div>
                  <div class="col d-flex align-items-center justify-content-center">
                    <ngx-skeleton-loader
                      class="order-loading w-100"
                      animation="progress-dark"
                      count="1" appearance="line"></ngx-skeleton-loader>
                  </div>
                  <div class="col d-flex align-items-center justify-content-center">
                    <ngx-skeleton-loader
                      class="order-loading w-100"
                      animation="progress-dark"
                      count="1" appearance="line"></ngx-skeleton-loader>
                  </div>
                  <div class="col-3 d-flex align-items-center justify-content-center">
                    <ngx-skeleton-loader
                      class="order-loading w-100"
                      animation="progress-dark"
                      count="1" appearance="line"></ngx-skeleton-loader>
                  </div>
                  <div class="col-1 d-flex align-items-center justify-content-center">
                    <ngx-skeleton-loader
                      class="order-loading w-100"
                      animation="progress-dark"
                      count="1" appearance="line"></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal>
  <app-purchase-order-details></app-purchase-order-details>
</app-modal>
