import {Currency} from 'src/app/shared/models/currency.model';
import {ChartDataSets} from 'chart.js';
import {Color, Label} from 'ng2-charts';

export class CoinChartModel {
  current: Currency;
  history: Currency[];
  chartData: ChartDataSets[];
  chartLabel: Label[];
  lineChartColors: Color[];

  constructor() {
  }
}
