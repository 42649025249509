export class Iban {
  id: number;
  iban: string;
  status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
  bankName ?: string;
  errors: any = {};

  constructor(input: any = {}) {
    this.id = input.id;
    this.iban = input.iban; // card
    this.status = input.status;
  }
}
