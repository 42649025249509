import {Component, OnInit} from '@angular/core';
import {FaqService} from 'src/app/shared/services/faq.service';
import {Category} from 'src/app/shared/models/faq.model';
import {Collapse} from 'bootstrap';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  category: Category[] = [];
  loading = true;
  collapseList: Collapse[] = [];

  constructor(private faqService: FaqService) {
  }

  expand(index: number): void {
    this.collapseList[index].toggle();
    this.category[index].isExpanded = !this.category[index].isExpanded;
  }

  ngOnInit(): void {
    this.faqService.getFaq().subscribe((response) => {
      this.category = response;
      this.loading = false;
      setTimeout(() => {
        const collapseElementList = [].slice.call(document.querySelectorAll('.collapse'));
        this.collapseList = collapseElementList.map((collapseEl) => {
          return new Collapse(collapseEl);
        });
      });
    });
  }

}
