export class Utility {
  static camelize(object) {
    let keys = Object.keys(object);
    let values = Object.values(object);
    let newObject = {};
    for (let i = 0; i < keys.length; i++) {
      const newKey = keys[i].replace(/^([A-Z])|[\s-_]+(\w)/g, function(
        match,
        p1,
        p2,
        offset
      ) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
      });
      newObject[newKey] = values[i];
    }
    return newObject;
  }
  static decamelize(object, separator = "_") {
    separator = typeof separator === "undefined" ? "_" : separator;
    let keys = Object.keys(object);
    let values = Object.values(object);
    let newObject = {};
    for (let i = 0; i < keys.length; i++) {
      const newKey = keys[i]
        .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
        .toLowerCase();
      newObject[newKey] = values[i];
    }
    return newObject;
  }
}
