import { Currency } from 'src/app/shared/models/currency.model';

export class Order {
  currencyId: string;
  currency: Currency;
  side = 'buy';
  amount: any = 1;
  price: number;

  constructor(input: any = {}) {

    if (input.currency) {
      this.currency = new Currency(input.currency);
    }
    this.side = input.side ?? 'buy';
    this.amount = input.amount ?? 1;
    this.price = input.price;
  }

}
