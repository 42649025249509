import {Injectable} from '@angular/core';
import {HttpService} from 'src/app/shared/services/http.service';
import {Order} from 'src/app/shared/models/order.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {OrderDetail} from 'src/app/shared/models/order-detail.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService {


  orderDetailStream = new BehaviorSubject<OrderDetail>(null);

  constructor(private http: HttpService) {
  }

  createOrder(order: Order): Observable<OrderDetail> {
    return this.http.request('post', '/v1/cart/create', order).pipe(
      map((response: any) => response.data),
      map((detail: any) => new OrderDetail(detail)),
    );
  }

  addCreditCard(orderId: number, cardId: number, networkId: string): Observable<any> {
    return this.http.request('put', '/v1/cart/credit-card', {
      id: orderId,
      credit_card_id: cardId,
      network_id: networkId
    });
  }
  addIban(orderId: number, cardId: number, networkId: string): Observable<any> {
    return this.http.request('put', '/v1/cart/iban', {
      id: orderId,
      iban_id: cardId,
      network_id: networkId,
    });
  }


  addWallet(orderId: number, walletId: number): Observable<any> {
    return this.http.request('put', '/v1/cart/wallet', {
      id: orderId,
      wallet_id: walletId,
    });
  }

  getPaymentLink(orderId: number): Observable<any> {
    return this.http.request('put', '/v1/cart/submit/buy', {
      id: orderId,
    });
  }
  submitSellRequest(orderId: number): Observable<any> {
    return this.http.request('put', '/v1/cart/submit/sell', {
      id: orderId,
    });
  }

  getOrderDetail(token: string): Observable<OrderDetail> {
    return this.http.request('put', '/v1/cart/submit/buy/verify', {
      payment_token: token,
    }).pipe(
      map((response: any) => response.data),
      map((data) => new OrderDetail(data)),
    );
  }

  getOrder(): Observable<OrderDetail[]> {
    return this.http.request('get', '/v1/request').pipe(
      map((response: any) => response.data),
      map((orders: any[]) => orders.map(order => new OrderDetail(order)))
    );
  }
}
