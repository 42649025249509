import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {RouterModule} from '@angular/router';
import {httpInterceptorProviders} from 'src/app/core/interceptor/http.interceptors';


@NgModule({
  declarations: [SidebarComponent],
  exports: [
    SidebarComponent
  ],
  providers: [
    httpInterceptorProviders
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class CoreModule {
}
