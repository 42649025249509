import {ForumCategoryModel} from './forum-category.model';
import {User} from './user.model';

export class GapModel {
  id: number;
  category: ForumCategoryModel;
  createdAt: string;
  isPinned: number;
  likes: number;
  count: number;
  repliesCount: number;
  likedByUser: boolean;
  message: string;
  title: string;
  updatedAt: string;
  reply: {
    count: number,
    data: GapModel[]
  };
  user: User;
  views: number;

  constructor(input: any = {}) {
    this.id = input.id;
    this.category = input.category;
    this.createdAt = input.created_at;
    this.isPinned = input.is_pinned;
    this.repliesCount = input.replies_count;
    this.likedByUser = input.liked_by_user;
    this.likes = input.likes;
    this.reply = {
      count: input.reply?.count,
      data: input.reply?.data.map((reply) => new GapModel(reply)) ?? []
    };
    this.message = input.message;
    this.title = input.title;
    this.user = new User(input.user);
    this.views = input.views;
    this.updatedAt = input.updated_at;
  }
}
