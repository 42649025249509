import {Injectable} from '@angular/core';
import {HttpService} from 'src/app/shared/services/http.service';
import {map} from 'rxjs/operators';
import {Currency} from 'src/app/shared/models/currency.model';
import {Observable} from 'rxjs';
import {ChartDataSets} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {CoinChartModel} from 'src/app/shared/models/coin.chart.model';

@Injectable({
  providedIn: 'root'
})
export class CoinService {

  constructor(private http: HttpService) {
  }

  getListOfCoins(): Observable<Currency[]> {
    return this.http.request('get', '/v1/currency').pipe(
      map((response: any) => response.data),
      map(data => data.map(coin => new Currency(coin))));
  }

  getCoinsHistory(list?): Observable<CoinChartModel[]> {
    return this.http.request('post', '/v1/currency/history', {coins: list}).pipe(
      map(((response: any) => response.data)),
      map(((data) => data.map(coin => {
        const coinChart = new CoinChartModel();
        coinChart.current = new Currency(coin);
        coinChart.history = coin.data.map(eachData => new Currency(eachData));
        if (coinChart.history[0]?.usdcPrice === 0 && coinChart.current.usdcPrice === 0) {
          coinChart.current.priceDiff = 0;
        } else {
          coinChart.current.priceDiff = 100 - (coinChart.history[0]?.usdcPrice * 100 / coinChart.current.usdcPrice) ?? 0;
        }
        return coinChart;
      }))),
    );
  }
}
