import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { SubSink } from 'subsink';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  sub = new SubSink();
  isSignedIn = this.authenticationService.checkUserSignedIn();
  user: User;
  @Input() isProfilePage = false;
  show: boolean = false;

  constructor(private authenticationService: AuthenticationService, private userService: UserService) {

  }

  signOut(): void {
    this.authenticationService.signOutWithoutRedirect();
    this.isSignedIn = false;
  }

  ngOnInit(): void {
    if (this.isSignedIn) {
      this.user = this.authenticationService.getUserInfo();
      this.sub.sink = this.userService.getUser().subscribe((user) => {
        this.user = user;
        console.log(user);
        this.authenticationService.setUserInfo(user);
        this.userService.userStream.next(user);
      });
      this.sub.sink = this.userService.userStream.subscribe((user) => {
        this.user = user;

      });
    }

  }
  public showMenu(): void {
    this.show = true;
  }
  public hideMenu(): void {
    this.show = false;
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
