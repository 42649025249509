import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FaqRoutingModule} from './faq-routing.module';
import {FaqComponent} from './conponents/faq/faq.component';
import {CoreModule} from 'src/app/core/core.module';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {SharedModule} from 'src/app/shared/shared.module';


@NgModule({
  declarations: [FaqComponent],
  imports: [
    CommonModule,
    FaqRoutingModule,
    CoreModule,
    NgxSkeletonLoaderModule,
    SharedModule
  ]
})
export class FaqModule {
}
