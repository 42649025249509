import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {OrderDetail} from 'src/app/shared/models/order-detail.model';
import {OrderService} from 'src/app/shared/services/order.service';
import {SubSink} from 'subsink';
import {User} from 'src/app/shared/models/user.model';
import {UserService} from 'src/app/shared/services/user.service';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {HttpError} from 'src/app/shared/models/http-error.model';
import Swal from 'sweetalert2';

export interface AlertModel {
  title?: string;
  message: string;
}

@Component({
  selector: 'app-purchase-order-details',
  templateUrl: './purchase-order-details.component.html',
  styleUrls: ['./purchase-order-details.component.scss']
})
export class PurchaseOrderDetailsComponent implements OnInit, OnDestroy {

  sub = new SubSink();
  orderDetail: OrderDetail;
  state = 1;
  user: User;
  nationalId: string;
  nationalIdImage: string;
  selfieImage: string;
  error = {};
  verificationStep = 1;
  Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  loading = false;
  type = 'buy';

  constructor(private orderService: OrderService, private authenticationService: AuthenticationService, private userService: UserService) {
  }

  getNationalImage($event: string): void {
    this.nationalIdImage = $event;
  }

  getSelfieImage($event: string): void {
    this.selfieImage = $event;
  }

  next(step: number): void {
    this.verificationStep = step;
  }

  getUser(): void {
    this.sub.sink = this.userService.getUser().subscribe((user) => {
      this.userService.userStream.next(user);
    });
  }

  saveVerification(): void {
    this.loading = true;
    this.userService.verification(this.nationalIdImage, this.selfieImage).subscribe(() => {
      this.orderDetail.status = 'FINISHED';
      this.getUser();
      this.state = 4;
      this.loading = false;
    }, (response: HttpError) => {
      if (response.status === 422) {
        this.error = response.validationError;
      } else {
        this.Toast.fire({icon: 'error', text: response.error});
      }
      this.loading = false;
    });
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUserInfo();
    this.sub.sink = this.orderService.orderDetailStream.subscribe((orderDetail) => {
      if (orderDetail) {
        this.orderDetail = orderDetail;
        this.type = orderDetail.type;
        if (orderDetail.status === 'USER_VERIFICATION') {
          this.state = 2;
        } else if (orderDetail.status === 'PENDING') {
          this.state = 3;
        } else if (orderDetail.status === 'FINISHED') {
          this.state = 4;
        } else {
          this.state = 3;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


}
