import {Pipe, PipeTransform} from '@angular/core';
import {Wallet} from 'src/app/shared/models/wallet';

@Pipe({
  name: 'currencyWallet'
})
export class CurrencyWalletPipe implements PipeTransform {

  transform(Wallets: Wallet[], currencyId: string): Wallet[] {
    return Wallets.filter(wallet => wallet?.currency?.id === currencyId) ?? [];
  }

}
