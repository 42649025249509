import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LivePriceRoutingModule } from './live-price-routing.module';
import {LivePriceComponent} from './components/live-price/live-price.component';
import {CoreModule} from '../../../core/core.module';
import {SharedModule} from '../../../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";


@NgModule({
  declarations: [LivePriceComponent],
    imports: [
        CommonModule,
        LivePriceRoutingModule,
        CoreModule,
        SharedModule,
        FormsModule,
        NgxSkeletonLoaderModule
    ],
  exports: [
    LivePriceComponent
  ]
})
export class LivePriceModule { }
