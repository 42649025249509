import {Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {

  }
}
