<div class="d-flex justify-content-between">
  <app-sidebar></app-sidebar>
  <div class="content  m-t-c">
    <div class="container-fluid">
      <p class="fs-20 fw-900 mb-32">زرارز را بیشتر بشناسید</p>
      <div class="card card-gradient">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-lg-6 d-flex flex-column align-items-center map-col">
              <p class="fw-bold fs-20 mb-11">زرارز استارتاپی پیشرو در زمینه ارزهای دیجیتال است که از سال ۱۳۹۹ با هدف فراهم
                آوردن
                بستری امن و سریع برای خرید و فروش ارزهای دیجیتال شروع به کار کرده است.</p>
              <div class="map-container mb-3 mb-lg-0">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1429.033372364107!2d51.38300966185819!3d35.72364192471425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e00d33a8a7b63%3A0x566bfb11c948fad9!2z2K_Yp9mG2LTaqdiv2Ycg2YXYr9uM2LHbjNiqINiv2KfZhti02q_Yp9mHINiq2YfYsdin2YY!5e0!3m2!1sfa!2snl!4v1622311044170!5m2!1sfa!2snl"
                  width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>
            <div class="col-12 col-lg-6  map-col">
              <div class="info-container">
                <p class="txt-color mb-10 fs-20 fw-900"> ساعات پاسخگویی:</p>
                <div class="row d-flex align-items-baseline">
                  <div class="col-12 col-md-9">
                    <p class="fw-bold mb-0 fs-18">۷ روز هفته ، ۲۴ ساعت شبانه‌روز پاسخگوی شما هستیم</p>
                  </div>
                  <div class="col-12 col-md-3 d-flex justify-content-end">
                    <a class="fs-24 fw-900 phone" href="tel:۰۲۱-۲۴۵۷۲۵۶۷">۰۲۱-۲۴۵۷۲۵۶۷</a>
                  </div>
                </div>
              </div>
              <div class="info-container">
                <p class="txt-color mb-10 fs-20 fw-900">زمان خرید و فروش:</p>
                <p class="fw-bold mb-0 fs-18">۷ روز هفته ، ۲۴ ساعت شبانه‌روز پاسخگوی شما هستیم</p>
              </div>
              <div class="info-container">
                <p class="txt-color mb-10 fs-20 fw-900">زمان احراز هویت:</p>
                <p class="fw-bold mb-0 fs-18">همه روزه حتی در ایام تعطیل از ساعت ۸صبح تا ۱۷ عصر</p>
              </div>
              <div class="info-container">
                <p class="txt-color mb-10 fs-20 fw-900">آدرس:</p>
                <a class="text-decoration-none text-white mb-0 fw-bold fs-18" target="_blank"
                   href="https://goo.gl/maps/4ES4896SVvxMeSES9"> تهران، مرکز نوآوری کسب و کار دانشگاه تهران واقع در
                  دانشکده مدیریت.
                  (مراجعات حضوری با هماهنگی قبلی)</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
