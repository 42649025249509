import {Component, Input, OnInit} from '@angular/core';
import {GapModel} from 'src/app/shared/models/gap.model';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit {
  @Input() forums: GapModel[];

  constructor() {
  }

  ngOnInit(): void {
  }

}
