import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  //
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const returnUrl = state.url;
    const authStatus = this.authenticationService.checkUserSignedIn();
    if (!authStatus) {
      this.goTo(returnUrl);
    }
    return of(authStatus)
  }
  private goTo(returnUrl: string) {
    this.router.navigate(['/authentication/sign-in'], {
      queryParams: { returnUrl },
    });
  }
}
