<div class="d-flex justify-content-between">
  <app-sidebar isProfilePage="true"></app-sidebar>
  <div class="content  m-t-c">
    <div class="row mb-5">
      <div class="col-12 col-xxl-3">
        <p class="fs-20 font-weight-heavy">مشخصات حساب کاربری شما</p>
        <div class="card specs-container">
          <div class="card-body">
            <div class="col-md-12">
              <div class="d-flex justify-content-between w-100 align-items-center">
                <div class="text-center position-relative">
                  <div (click)="showAvatarList()" class="background-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.283" height="12.283"
                         viewBox="0 0 12.283 12.283">
                      <g id="Edit" transform="translate(0)" opacity="0.7">
                        <path id="Fill_1"
                              d="M4.573,0H.687A.694.694,0,0,0,0,.7a.693.693,0,0,0,.687.7H4.573A.693.693,0,0,0,5.261.7.694.694,0,0,0,4.573,0"
                              transform="translate(7.023 10.887)" fill="#131315"
                              opacity="0.4"/>
                        <path id="Fill_3"
                              d="M4.988.046,8.67,3.021a.212.212,0,0,1,.035.293L4.339,9a1.433,1.433,0,0,1-1.112.558L.844,9.589a.272.272,0,0,1-.267-.213L.035,7.021A1.459,1.459,0,0,1,.31,5.8L4.7.081A.2.2,0,0,1,4.988.046"
                              transform="translate(0 2.619)" fill="#131315"/>
                        <path id="Fill_5"
                              d="M4.761,3.866l-.71.886a.2.2,0,0,1-.289.033C2.9,4.087.69,2.295.076,1.8A.21.21,0,0,1,.047,1.5L.731.653A1.755,1.755,0,0,1,3.31.477l1,.8a2.092,2.092,0,0,1,.78,1.2,1.642,1.642,0,0,1-.332,1.394"
                              transform="translate(5.557)" fill="#131315" opacity="0.4"/>
                      </g>
                    </svg>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="avatar-holder d-flex align-items-center justify-content-center"
                         [style]="'border-color:' + user.avatar?.color">
                      <div class="avatar-circle d-flex justify-content-center align-items-end position-relative"
                           [style]="'background:'+user.avatar?.color">
                        <img *ngIf="user.avatar?.icon" width="65" height="65" [src]="user.avatar?.icon" alt="avatar">
                        <div class="backdrop" [style]="'background:url('+user.avatar?.icon+')'"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="authenticate-div p-2 d-flex align-items-center justify-content-between">
                  <div>
                    <svg *ngIf="user.authenticated === 'ACCEPTED'" id="Iconly_Bulk_Discount"
                         data-name="Iconly/Bulk/Discount"
                         xmlns="http://www.w3.org/2000/svg" width="16" height="15.999"
                         viewBox="0 0 16 15.999">
                      <path id="Fill_1" data-name="Fill 1"
                            d="M8,16a2.4,2.4,0,0,1-1.7-.7l-.584-.584a1.2,1.2,0,0,0-.851-.351H4.044a2.412,2.412,0,0,1-2.409-2.409v-.822a1.207,1.207,0,0,0-.352-.852L.708,9.707A2.412,2.412,0,0,1,.7,6.3l.584-.585a1.2,1.2,0,0,0,.351-.851V4.045a2.412,2.412,0,0,1,2.409-2.41h.822a1.2,1.2,0,0,0,.851-.353L6.292.709A2.411,2.411,0,0,1,9.7.7l.584.584a1.2,1.2,0,0,0,.85.351h.822a2.412,2.412,0,0,1,2.409,2.41v.822a1.207,1.207,0,0,0,.352.852l.574.574a2.408,2.408,0,0,1,.016,3.4c-.008.008-.015.017-.023.024l-.568.568a1.2,1.2,0,0,0-.351.851v.822a2.412,2.412,0,0,1-2.409,2.409h-.822a1.2,1.2,0,0,0-.851.352l-.575.574A2.4,2.4,0,0,1,8,16"
                            transform="translate(0 0)" fill="#555556"/>
                      <path id="Fill_4" data-name="Fill 4"
                            d="M2.97,5.94A.8.8,0,0,1,2.4,5.706L.235,3.536A.8.8,0,0,1,1.366,2.4l1.6,1.6L6.744.235A.8.8,0,1,1,7.875,1.366L3.536,5.706a.8.8,0,0,1-.566.234"
                            transform="translate(4.403 4.888)" fill="#e6cd64"/>
                    </svg>
                    <svg *ngIf="user.authenticated !== 'ACCEPTED'" xmlns="http://www.w3.org/2000/svg"
                         width="17.499" height="17.499" viewBox="0 0 17.499 17.499">
                      <g id="Group_390" data-name="Group 390"
                         transform="translate(-1796.75 -835.654)">
                        <g id="Iconly_Bulk_Discount"
                           data-name="Iconly/Bulk/Discount"
                           transform="translate(1796.75 835.654)">
                          <path id="Fill_1" data-name="Fill 1"
                                d="M8.749,17.5a2.629,2.629,0,0,1-1.858-.766l-.639-.639a1.316,1.316,0,0,0-.931-.384h-.9a2.638,2.638,0,0,1-2.635-2.635v-.9a1.32,1.32,0,0,0-.385-.932l-.628-.627a2.638,2.638,0,0,1-.01-3.725l.639-.64a1.316,1.316,0,0,0,.384-.931v-.9A2.638,2.638,0,0,1,4.422,1.788h.9A1.308,1.308,0,0,0,6.253,1.4L6.882.775a2.637,2.637,0,0,1,3.725-.01l.639.639a1.31,1.31,0,0,0,.93.384h.9A2.638,2.638,0,0,1,15.71,4.424v.9a1.32,1.32,0,0,0,.385.932l.628.628a2.633,2.633,0,0,1,.017,3.716c-.009.009-.017.018-.025.026l-.621.621a1.316,1.316,0,0,0-.384.931v.9a2.638,2.638,0,0,1-2.635,2.635h-.9a1.317,1.317,0,0,0-.931.385l-.629.628a2.624,2.624,0,0,1-1.866.775"
                                fill="#fff" style="fill: #707070;"></path>
                          <path id="Combined_Shape" data-name="Combined Shape"
                                d="M.005,8.468a.878.878,0,1,1,.879.875A.874.874,0,0,1,.005,8.468ZM0,5.295V.875a.875.875,0,0,1,1.75,0v4.42a.875.875,0,1,1-1.75,0Z"
                                transform="translate(7.75 3.586)" fill="#fff"
                                opacity="0.879"></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="text-nowrap">
                      <span *ngIf="user.authenticated !== 'ACCEPTED'"
                            class="fs-12 me-2 font-weight-bold waiting">هویت در انتظار تایید</span>
                    <span *ngIf="user.authenticated === 'ACCEPTED'" class="fs-12 me-2 font-weight-bold">هویت تایید شده</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="d-flex w-100 justify-content-between mt-3">
                <div class="font-weight-regular  fs-18">نام و نام خانوادگی:</div>
                <div class="fs-18  font-weight-bold">{{fullName}}</div>
              </div>
              <div class="d-flex w-100 justify-content-between mt-3">
                <div class="font-weight-regular  fs-18">شماره موبایل :</div>
                <div class="fs-18  font-weight-bold">{{user.phone}}</div>
              </div>
              <div class="d-flex w-100 justify-content-between mt-3">
                <div class="font-weight-regular  fs-18">نام پدر :</div>
                <div class="fs-18  font-weight-bold">{{user.fatherName}}</div>
              </div>
              <div class="d-flex w-100 justify-content-between mt-3">
                <div class="font-weight-regular  fs-18">تاریخ تولد :</div>
                <div class="fs-18  font-weight-bold">{{user.birthday | dateFormat:dateFormat}}</div>
              </div>
              <div class="d-flex w-100 justify-content-between mt-3">
                <div class="font-weight-regular  fs-18">تاریخ عضویت :</div>
                <div class="fs-18  font-weight-bold">{{user.createdAt | dateFormat:dateFormat}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xxl-9 mt-2 mt-md-0">
        <p class="fs-20 font-weight-heavy">مشخصات حساب بانکی</p>
        <div class="card bank-container p-3">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4 position-relative">
                <ng-container *ngIf="cardLoading">
                  <div class="loading">
                    <div class="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <p class="fs-16 font-weight-regular">در حال {{cardLoadingData ? 'دریافت' : 'ثبت'}} کارت بانکی
                      ...</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="!bankCardRegister">
                  <form [formGroup]="cardForm" [ngStyle]="{'filter' : cardLoading ? 'blur(16px)': 'unset'}">
                    <div class="row justify-content-center">
                      <p class="font-weight-regular fs-16">برای خرید ارز باید کارت بانکی به نام {{fullName}} ثبت
                        نمایید</p>
                    </div>
                    <div class="row justify-content-center mb-4">
                      <input class="credit-card-input" maxlength="24" dir="ltr"
                             placeholder="xxxx xxxx xxxx xxxx"
                             formControlName="creditCardNum"
                             type="tel"
                             #ccNumber
                             name="card"
                             [(ngModel)]="userCard.card"
                             (keyup)="creditCardNumberSpacing()"
                             [ngClass]="(userCard.errors && userCard.errors.card) ? 'is-invalid' : ''">
                      <app-validation-error [errors]="userCard.errors" name="card"></app-validation-error>
                    </div>
                    <div disabled *ngIf="false" class="row">
                      <div class="col-6 px-2">
                        <input disabled class="text-center w-100 mb-3 py-2">
                      </div>
                      <div class="col-6 px-2">
                        <input disabled class="text-center w-100 mb-3 py-2">
                      </div>
                    </div>
                    <div class="row justify-content-center border-bottom pb-4 mb-4">
                      <div class="col-7 d-flex justify-content-center">
                        <button class="btn btn-primary fs-16 font-weight-bold" [disabled]="!userCard.card"
                                (click)="saveCard()">
                          ثبت کارت
                        </button>
                      </div>
                    </div>
                    <div *ngIf="false" class="row">
                      <div class="col-6">
                        <button type="submit" class="btn btn-primary">ثبت کارت</button>
                      </div>
                      <div class="col-6">
                        <button type="button" class="btn btn-edit">ویرایش کارت</button>
                      </div>
                    </div>
                  </form>
                </ng-container>

                <div *ngIf="bankCardRegister" class="row justify-content-center border-bottom pb-4 mb-4">
                  <div class="col-6 text-center">
                    <button class="btn btn-primary" (click)="bankCardRegister = !bankCardRegister">ثبت شماره کارت جدید
                    </button>
                  </div>
                </div>
                <ng-container *ngIf="userCardList.length > 0">
                  <div class="w-100" [ngClass]="{ 'border-bottom mb-4 pb-4' : i < userCardList.length - 1 }" *ngFor="let card of userCardList; let i = index">
                    <div class="bank-card p-3 mb-3 col-12" [ngStyle]="{'filter' : cardLoading ? 'blur(16px)': 'unset'}">
                      <div class="row justify-content-center">
                        <div class="col-6 d-flex justify-content-center">
                          <div class=" authenticate-div d-flex p-2">
                            <div>
                              <svg *ngIf="card.card" id="Iconly_Bulk_Discount" data-name="Iconly/Bulk/Discount"
                                   xmlns="http://www.w3.org/2000/svg" width="16" height="15.999"
                                   viewBox="0 0 16 15.999">
                                <path id="Fill_1" data-name="Fill 1"
                                      d="M8,16a2.4,2.4,0,0,1-1.7-.7l-.584-.584a1.2,1.2,0,0,0-.851-.351H4.044a2.412,2.412,0,0,1-2.409-2.409v-.822a1.207,1.207,0,0,0-.352-.852L.708,9.707A2.412,2.412,0,0,1,.7,6.3l.584-.585a1.2,1.2,0,0,0,.351-.851V4.045a2.412,2.412,0,0,1,2.409-2.41h.822a1.2,1.2,0,0,0,.851-.353L6.292.709A2.411,2.411,0,0,1,9.7.7l.584.584a1.2,1.2,0,0,0,.85.351h.822a2.412,2.412,0,0,1,2.409,2.41v.822a1.207,1.207,0,0,0,.352.852l.574.574a2.408,2.408,0,0,1,.016,3.4c-.008.008-.015.017-.023.024l-.568.568a1.2,1.2,0,0,0-.351.851v.822a2.412,2.412,0,0,1-2.409,2.409h-.822a1.2,1.2,0,0,0-.851.352l-.575.574A2.4,2.4,0,0,1,8,16"
                                      transform="translate(0 0)" fill="#555556"/>
                                <path id="Fill_4" data-name="Fill 4"
                                      d="M2.97,5.94A.8.8,0,0,1,2.4,5.706L.235,3.536A.8.8,0,0,1,1.366,2.4l1.6,1.6L6.744.235A.8.8,0,1,1,7.875,1.366L3.536,5.706a.8.8,0,0,1-.566.234"
                                      transform="translate(4.403 4.888)" fill="#e6cd64"/>
                              </svg>
                            </div>
                            <div class="text-nowrap">
                              <span *ngIf="card.status === 'ACCEPTED'" class="fs-12 font-weight-bold me-1">تایید شده</span>
                              <span *ngIf="card.status === 'REJECTED'" class="fs-12 font-weight-bold me-1">تایید نشده</span>
                              <span *ngIf="card.status === 'PENDING'" class="fs-12 font-weight-bold me-1">در حال بررسی</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between mb-2 align-items-center">
                        <p class="fs-18 font-weight-bold mb-0">{{card.bankName}}</p>
                        <p class="fs-18 font-weight-bold mb-0">{{card.cardHolderName}}</p>

                      </div>
                      <div class="d-flex justify-content-between mb-2 align-items-center">
                        <img style="width: 54px; height: 37px; margin-right: auto;" src="assets/images/chip.png">
                      </div>
                      <div dir="ltr" class="d-flex justify-content-around mt-4">
                        <span *ngFor="let cardSection of card.cardSplit"
                              class="text-center fs-22 font-weight-bold">{{cardSection | formatNumber: 'toPersian'}}</span>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-6 text-center">
                        <button type="button" [disabled]="deleteCardLoading"
                                class="btn btn-secondary fs-16 font-weight-bold" (click)="removeCard(i)">
                          <ng-container *ngIf="deleteCardLoading == false  else loadingSpinner">
                            حذف کارت
                          </ng-container>
                          <ng-template #loadingSpinner>
                            <i class="spinner d-flex justify-content-center align-items-center ms-2">
                              <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="currentColor"
                                      d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
                              </svg>
                            </i>
                          </ng-template>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="col-lg-5 d-flex justify-content-center position-relative">
                <ng-container *ngIf="shebaLoading">
                  <div class="loading">
                    <div class="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <p class="fs-16 font-weight-regular">در حال {{shebaLoadingData ? 'دریافت' : 'ثبت'}} شماره شبا
                      ...</p>
                  </div>
                </ng-container>
                <ng-container>
                  <form [ngStyle]="{'filter' : shebaLoading ? 'blur(16px)': 'unset'}">
                    <div class="row justify-content-center" *ngIf="userIbanList.length < 1">
                      <p class="fs-16 font-weight-regular"> برای فروش ارز باید یک شماره شبا به نام {{fullName}} ثبت
                        نمایید. </p>
                    </div>
                    <div dir="ltr" class="row justify-content-center position-relative mb-4" *ngIf="!shabaNumFill">
                      <span class="prefix p-0 fs-17 font-weight-bold">IR</span>
                      <input [disabled]="shabaNumFill" [class.is-invalid]="userIban.errors['iban']"
                             class="credit-card-input font-weight-regular fs-18"
                             maxlength="24"
                             name="sheba"
                             type="tel" [(ngModel)]="userIban.iban"
                             [ngClass]="(userIban.errors && userIban.errors.iban) ? 'is-invalid' : ''">
                      <app-validation-error [errors]="userIban.errors" name="iban"></app-validation-error>
                    </div>
                    <div *ngIf="!shabaNumFill" class="row justify-content-center border-bottom pb-4 mb-4">
                      <div class="col-6 text-center">
                        <button class="btn btn-primary" [disabled]="!userIban.iban" (click)="saveIban()">ثبت شماره شبا
                        </button>
                      </div>
                    </div>

                    <div *ngIf="shabaNumFill" class="row justify-content-center border-bottom pb-4 mb-4 mt-3 mt-lg-0">
                      <div class="col-6 text-center">
                        <button class="btn btn-primary" (click)="shabaNumFill = !shabaNumFill">ثبت شماره شبا جدید
                        </button>
                      </div>
                    </div>

                    <ng-container *ngIf="userIbanList.length > 0">
                      <div dir="ltr" class="row justify-content-center position-relative mb-4"
                           [ngClass]="{ 'border-bottom pb-4' : i < userIbanList.length - 1 }"
                           *ngFor="let iban of userIbanList; let i = index">
                        <div class="col-12">

                          <div class="row justify-content-center mb-3">
                            <div class="col-6 d-flex justify-content-center">
                              <div class=" authenticate-div d-flex p-2" dir="rtl">
                                <div>
                                  <svg *ngIf="iban.iban" id="Iconly_Bulk_Discount" data-name="Iconly/Bulk/Discount"
                                       xmlns="http://www.w3.org/2000/svg" width="16" height="15.999"
                                       viewBox="0 0 16 15.999">
                                    <path id="Fill_1" data-name="Fill 1"
                                          d="M8,16a2.4,2.4,0,0,1-1.7-.7l-.584-.584a1.2,1.2,0,0,0-.851-.351H4.044a2.412,2.412,0,0,1-2.409-2.409v-.822a1.207,1.207,0,0,0-.352-.852L.708,9.707A2.412,2.412,0,0,1,.7,6.3l.584-.585a1.2,1.2,0,0,0,.351-.851V4.045a2.412,2.412,0,0,1,2.409-2.41h.822a1.2,1.2,0,0,0,.851-.353L6.292.709A2.411,2.411,0,0,1,9.7.7l.584.584a1.2,1.2,0,0,0,.85.351h.822a2.412,2.412,0,0,1,2.409,2.41v.822a1.207,1.207,0,0,0,.352.852l.574.574a2.408,2.408,0,0,1,.016,3.4c-.008.008-.015.017-.023.024l-.568.568a1.2,1.2,0,0,0-.351.851v.822a2.412,2.412,0,0,1-2.409,2.409h-.822a1.2,1.2,0,0,0-.851.352l-.575.574A2.4,2.4,0,0,1,8,16"
                                          transform="translate(0 0)" fill="#555556"/>
                                    <path id="Fill_4" data-name="Fill 4"
                                          d="M2.97,5.94A.8.8,0,0,1,2.4,5.706L.235,3.536A.8.8,0,0,1,1.366,2.4l1.6,1.6L6.744.235A.8.8,0,1,1,7.875,1.366L3.536,5.706a.8.8,0,0,1-.566.234"
                                          transform="translate(4.403 4.888)" fill="#e6cd64"/>
                                  </svg>
                                </div>
                                <div class="text-nowrap">
                                  <span *ngIf="iban.status === 'ACCEPTED'" class="fs-12 font-weight-bold me-1">تایید شده</span>
                                  <span *ngIf="iban.status === 'REJECTED'" class="fs-12 font-weight-bold me-1">تایید نشده</span>
                                  <span *ngIf="iban.status === 'PENDING'" class="fs-12 font-weight-bold me-1">در حال بررسی</span>
                                </div>
                              </div>
                            </div>
                          </div>




                          <div dir="ltr" class="row justify-content-center position-relative mb-4">
                            <span class="prefix p-0 fs-17 font-weight-bold">IR</span>
                            <input [disabled]="true" [class.is-invalid]="iban.errors['iban']"
                                   class="credit-card-input font-weight-regular fs-18"
                                   maxlength="24"
                                   name="sheba-{{i}}"
                                   type="tel" [(ngModel)]="iban.iban"
                                   [ngClass]="(iban.errors && iban.errors.iban) ? 'is-invalid' : ''">
                            <app-validation-error [errors]="iban.errors" name="iban-{{i}}"></app-validation-error>
                          </div>
                          <div class="row">
                            <div class="col-6 mb-15">
                              <div class="content-value font-weight-regular fs-18 b-radius-1 justify-content-center align-items-center d-flex">
                                {{fullName}}
                              </div>
                            </div>
                            <div class="col-6 mb-15">
                              <div class="content-value font-weight-regular fs-18 b-radius-1 justify-content-center align-items-center d-flex">
                                {{iban.bankName}}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row justify-content-center">
                          <!--                          <div class="col-6">-->
                          <!--                            <button class="btn btn-primary fs-16 font-weight-bold"-->
                          <!--                                    (click)="saveIban()">-->
                          <!--                              ثبت شماره شبا-->
                          <!--                            </button>-->
                          <!--                          </div>-->
                          <div class="col-6 text-center">
                            <button
                              type="button" class="btn btn-secondary" [disabled]="deleteShabaLoading"
                              (click)="removeIban(i)">
                              <ng-container *ngIf="deleteShabaLoading == false  else loadingSpinner">
                                حذف شماره شبا
                              </ng-container>
                              <ng-template #loadingSpinner>
                                <i class="spinner d-flex justify-content-center align-items-center ms-2">
                                  <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                                       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                          d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
                                  </svg>
                                </i>
                              </ng-template>
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>





                  </form>
                </ng-container>
              </div>
              <div class="col-lg-3">
                <p class="fs-17 font-weight-regular attention">
                  <svg class="ms-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <g id="Group_374" data-name="Group 374" transform="translate(-615.881 -189)">
                      <circle id="Ellipse_75" data-name="Ellipse 75" cx="8" cy="8" r="8"
                              transform="translate(615.881 189)" fill="#845ea5" opacity="0.391"/>
                      <circle id="Ellipse_76" data-name="Ellipse 76" cx="6" cy="6" r="6"
                              transform="translate(617.881 191)" fill="#845ea5" opacity="0.357"/>
                      <circle id="Ellipse_77" data-name="Ellipse 77" cx="4" cy="4" r="4"
                              transform="translate(619.881 193)" fill="#845ea5"/>
                    </g>
                  </svg>
                  توجه داشته باشید که برای
                  ثبت اطلاعات بانکی نیاز
                  هست مشخصات دارنده
                  حساب با مشخصات حساب
                  کاربری شما یکسان باشد.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-5 col-xxl-3">
        <app-alert #AlertComponent></app-alert>
      </div>
      <div class="col-lg-6 col-xxl-4 mt-2 mt-md-0">
        <p class="fs-20 font-weight-heavy">کیف پول های شما</p>
        <div class="card">
          <div class="card-body px-4">

            <ng-container *ngIf="userWallet;else walletsSkeleton">
              <div *ngIf="userWallet.length === 0;else walletsElement" class="row h-100 my-3">
                <div class="col-12 h-100 py-5 d-flex flex-column justify-content-end align-items-center">
                  <b class="text-center fs-16 font-weight-bold mb-3"> هیچ کیف پولی ثبت نشده است. </b>
                  <p class="text-center fs-16 font-weight-regular mb-3">برای خرید ارز باید آدرس کیف پول خود را ثبت
                    نمایید.</p>
                  <img class="arrow" src="assets/images/right-drawn-arrow.png">
                </div>
              </div>
              <ng-template #walletsElement>
                <div class="row me-3">
                  <div class="col-6">
                    <span class="ms-3 fs-16 font-weight-regular">نام ارز</span>
                  </div>
                  <div class="col-6 text-start">
                    <span class="ms-3 fs-16 font-weight-regular">نام کیف پول</span>
                  </div>
                </div>
                <div *ngFor="let wallet of userWallet?.reverse()" class="row over-card my-3">
                  <div class="col-12">
                    <div class="row my-3">
                      <div class="col-6 d-flex align-items-center justify-content-start">
                        <img *ngIf="wallet.currency.iconPath" class="coin-icon ms-2" [src]="wallet.currency.iconPath"
                             alt="coin">
                        <span class="ms-sm-2 fs-18 font-weight-regular text-nowrap">{{wallet.currency.name}}</span>
                        <span class="fs-14 font-weight-light">({{wallet.currency.id}})</span>
                      </div>
                      <div class="col-6 text-start">
                        <span class="fs-16 font-weight-bold">{{wallet.name}}</span>
                      </div>
                    </div>
                    <div class="row token-container p-1 text-center my-3">
                      <span class="py-2 fs-14 font-weight-light">{{wallet.wallet}}</span>
                    </div>
                    <div class="row justify-content-center my-3">
                      <div class="col-6 d-flex justify-content-center">
                        <button [disabled]="deleteWalletLoading && deletedWalletId === wallet.id"
                                class="btn btn-delete p-2 fs-14 font-weight-bold" (click)="removeWallet(wallet)">
                          <ng-container
                            *ngIf="deleteWalletLoading == false || deletedWalletId !== wallet.id  else loadingSpinner">
                            حذف کیف پول
                          </ng-container>
                          <ng-template #loadingSpinner>
                            <i class="spinner d-flex justify-content-center align-items-center ms-2">
                              <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="currentColor"
                                      d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
                              </svg>
                            </i>
                          </ng-template>

                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #walletsSkeleton>
              <div class="row over-card my-3">
                <div class="col-12">
                  <div class="row align-items-center my-3">
                    <div class="col-6 d-flex align-items-center justify-content-start">
                      <ngx-skeleton-loader class="icon-loading ms-2"
                                           animation="progress-dark"
                                           count="1" appearance="circle"></ngx-skeleton-loader>
                      <ngx-skeleton-loader class="text-loading"
                                           animation="progress-dark"
                                           count="1" appearance="line"></ngx-skeleton-loader>
                    </div>
                    <div class="col-6 text-start">
                      <ngx-skeleton-loader class="text-loading"
                                           animation="progress-dark"
                                           count="1" appearance="line"></ngx-skeleton-loader>
                    </div>
                  </div>
                  <div class="row token-container p-1 text-center my-3">
                    <ngx-skeleton-loader class="text-loading"
                                         animation="progress-dark"
                                         count="1" appearance="line"></ngx-skeleton-loader>
                  </div>
                  <div class="row justify-content-center my-3">
                    <div class="col-12 d-flex justify-content-center">
                      <ngx-skeleton-loader class="action-loading"
                                           animation="progress-dark"
                                           count="1" appearance="line"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-12 col-xxl-5 mt-2 mt-lg-0">
        <p class="fs-20 font-weight-heavy">ایجاد کیف پول جدید </p>
        <div class="card">
          <div class="card-body">
            <!-- <div class="row mt-3 mb-2">
              <div class="col-6">
                <span class="fs-16 font-weight-regular">نام ارز</span>
              </div>
              <div class="col-6">
                <span class="fs-16 font-weight-regular">نام کیف پول</span>
              </div>
            </div> -->
            <div class="row mb-4 mt-3">
              <div class="col-12 col-md-6">
                <span class="fs-16 font-weight-regular">نام ارز</span>

                <ng-select class="mt-2" [searchable]="false" [clearable]="false" [(ngModel)]="tradeCoin">
                  <ng-option *ngFor="let coin of coins" [value]="coin">
                    <div class="d-flex w-100 align-items-center">
                      <img class="coin-icon ms-2" [src]="coin.iconPath" alt="btc">
                      <span>{{coin.name}} ({{coin.id}})</span>
                    </div>
                  </ng-option>
                </ng-select>
                <app-validation-error name="currency_id" [errors]="error"></app-validation-error>

              </div>
              <div class="col-12 col-md-6 mt-4 mt-md-0">
                <span class="fs-16 font-weight-regular">نام کیف پول</span>

                <div class="bill-input-container mt-2">
                  <input [(ngModel)]="saveWallet.name" class="w-100 bill-input"
                  [ngClass]="error.name ? 'is-invalid' : ''"
                  placeholder="برای کیف پول خود اسمی انتخاب کنید">
                  <app-validation-error name="name" [errors]="error"></app-validation-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 bill-input-container" [class.col-xl-6]="tradeCoin?.hasTag">
                <label class="fs-16 font-weight-regular mb-2">آدرس کیف پول</label>
                <input [(ngModel)]="saveWallet.wallet" class="py-3 px-2 w-100 text-end"
                       placeholder="آدرس کیف پول خود را وارد کنید" [ngClass]="error.wallet ? 'is-invalid' : ''">
                <app-validation-error name="wallet" [errors]="error"></app-validation-error>

              </div>
              <div *ngIf="tradeCoin?.hasTag" class="col-12 col-xl-6 bill-input-container">
                <label class="d-flex fs-16 font-weight-regular mb-2">تگ یا ممو</label>
                <input [(ngModel)]="saveWallet.tag" class="py-3 px-2 w-100 text-end"
                       placeholder="تگ یا ممو" [ngClass]="error.tag ? 'is-invalid' : ''">
                <app-validation-error name="wallet" [errors]="error"></app-validation-error>

              </div>
            </div>
            <div class="row mt-3">
              <p class="fs-14 font-weight-regular wallet-alert">
                برای خرید ارز شما فقط مجاز به ثبت آدرس کیف پول خودتان هستید زرارز هیچگونه مسئولیتی در قبال سایر آدرس
                کیف پولها نظیر درگاه‌های پرداخت، فروشگاه های آنلاین و… بر عهده نمی گیرد.
              </p>
            </div>
            <div class="row justify-content-center my-3">
              <div class="col-12 text-center">
                <button (click)="createWallet()" [disabled]="saveWalletLoading" type="submit"
                        class="btn btn-primary save-btn">
                  <ng-container
                    *ngIf="saveWalletLoading == false else loadingSpinner">
                    ثبت
                  </ng-container>
                  <ng-template #loadingSpinner>
                    <i class="spinner d-flex justify-content-center align-items-center ms-2">
                      <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                              d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
                      </svg>
                    </i>
                  </ng-template>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<app-notification-modal [cryptoList]="coins" [currency]="btc"
                        (refreshAlertList)="AlertComponent.getAlertList()"></app-notification-modal>

<app-modal [maxWidth]="450">
  <strong class="fs-20 d-block text-center mt-2">انتخاب عکس پروفایل</strong>
  <div class="avatar-list mt-4 row">
    <div class="col-4 d-flex justify-content-center" *ngFor="let avatar of avatarList;let i=index">
      <div (click)="setNewAvatar(avatar)"
           class="avatar-holder d-flex align-items-center justify-content-center cursor-pointer"
           [style]="'border-color:' + avatar?.color">
        <div class="avatar-circle d-flex justify-content-center align-items-end position-relative"
             [style]="'background:'+avatar?.color">
          <img *ngIf="avatar?.icon" width="65" height="65" [src]="avatar?.icon" alt="avatar">
          <div class="backdrop" [style]="'background:url('+avatar?.icon+')'"></div>
        </div>
      </div>
    </div>
  </div>
</app-modal>
