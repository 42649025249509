export class HttpError {
  status: number;
  error: string;
  validationError: any;
  errors: any;

  constructor(error: any = {}) {
    Object.assign(this, error);
  }
}
