import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import { SubSink } from 'subsink';
import { Modal } from 'bootstrap';
import { OrderService } from 'src/app/shared/services/order.service';
import { OrderDetail } from 'src/app/shared/models/order-detail.model';
import { UserService } from 'src/app/shared/services/user.service';
import { Card } from 'src/app/shared/models/card.model';
import { Router } from '@angular/router';
import { Wallet } from 'src/app/shared/models/wallet';
import { User } from 'src/app/shared/models/user.model';
import { Iban } from 'src/app/shared/models/iban.model';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-pre-buy-invoice',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit, OnDestroy {
  private sub = new SubSink();
  @Input() type = 'buy';
  title: string;
  message: string;
  selected = false;
  step = '';
  orderModal: Modal;
  orderDetail: OrderDetail;
  policyAccepted = false;
  acceptSendTime = false;
  creditCards: Card[] = [];
  wallets: Wallet[] = [];
  modalEvent: Event;
  loading = false;
  user: User;
  ibanList: Iban[] = [];
  sellWalletAddress: string;
  sellMemoTag: string;
  Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  constructor(
    public modalService: ModalService,
    private orderService: OrderService,
    private userService: UserService,
    private router: Router) {
  }

  changeStep(step): void {
    if (step === 'step1') {
      this.policyAccepted = false;
      this.loading = false;
    }
    this.step = step;
  }

  goToProfile(): void {
    this.router.navigateByUrl('/profile');
    this.orderModal.hide();
  }

  addCreditCard(): void {
    this.loading = true;
    this.orderService.addCreditCard(this.orderDetail.id, this.orderDetail.cardId, this.orderDetail.network.network)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((response) => {
        console.log('this.orderDetail', this.orderDetail);
        this.orderDetail.wallet = undefined;
        this.changeStep('step4');
      });
  }

  addIban(): void {
    this.loading = true;
    console.log('this.orderDetail', this.orderDetail);
    this.orderService.addIban(this.orderDetail.id, this.orderDetail.ibanId, this.orderDetail.network.network)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((response) => {
        this.sellWalletAddress = response.data?.wallet;
        this.sellMemoTag = response.data?.memo;
        this.changeStep('step4');
      }, error => {

      });
  }

  addWallet(): void {
    this.loading = true;
    this.orderService.addWallet(this.orderDetail.id, this.orderDetail.wallet?.id)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((response) => {
        this.changeStep('step5');
      });
  }

  getPaymentLink(): void {
    this.loading = true;
    this.orderService.getPaymentLink(this.orderDetail.id)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((response) => {
        location.replace(response.data?.link);
      });
  }

  submitSellRequest(): void {
    this.loading = true;
    this.orderService.submitSellRequest(this.orderDetail.id)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((response) => {
        this.orderModal.hide();
        this.router.navigateByUrl('/order-list');
      });
  }

  ngOnInit(): void {
    this.step = 'step1';
    this.sub.sink = this.modalService.modalStream.subscribe((modal) => {
      this.orderModal = modal;
    });
    this.sub.sink = this.orderService.orderDetailStream.subscribe((detail) => {
      if (detail && detail.networks?.length > 0) {
        this.orderDetail = detail;
      } else {
        this.orderDetail = undefined;
      }
    });
    this.sub.sink = this.userService.getCard().subscribe((creditCards) => {
      this.creditCards = creditCards;
      this.creditCards.filter(card => {
        card.bankName = this.userService.getBankName({
          type: 'card',
          value: card.card
        });
      });
    });
    this.sub.sink = this.userService.getIban().subscribe((ibanList) => {
      this.ibanList = ibanList;
      this.ibanList.filter(iban => {
        iban.iban = iban.iban.replace('IR', '');
        iban.bankName = this.userService.getBankName({
          type: 'sheba',
          value: iban.iban
        });
      });
    });
    this.sub.sink = this.userService.getWallet().subscribe((wallets) => {
      this.wallets = wallets;
    });
    const modalElement = document.getElementById('modal');
    modalElement?.addEventListener('hidden.bs.modal', this.changeStep.bind(this, 'step1'));
    this.sub.sink = this.userService.userStream.subscribe((user) => {
      this.user = user;
    });
  }

  copied(): void {
    if (window.navigator) {
      navigator.clipboard.writeText(this.sellWalletAddress).then(() => {
        this.Toast.fire({ icon: 'success', text: 'آدرس کیف پول با موفقیت کپی شد.' });
      });
    }

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    const modalElement = document.getElementById('modal');
    modalElement?.removeEventListener('hidden.bs.modal', this.changeStep);

  }

  sellSetOrderDetailIban(value): void {
    this.orderDetail.ibanId = value;
  }

  buySetOrderDetailCard(cardId: number): void {
    this.orderDetail.cardId = cardId;
  }

  setOrderDetailWallet(wallet: Wallet): void {
    this.orderDetail.wallet = wallet;
  }
}
