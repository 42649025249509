import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from 'src/app/feature/components/dashboard/dashboard.component';
import {LivePriceComponent} from './modules/live-price/components/live-price/live-price.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'payment/verify',
    component: DashboardComponent
  },
  {
    path: 'authentication',
    loadChildren: () => import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule)
  },
  {path: 'gap', loadChildren: () => import('./modules/gap/gap.module').then((m) => m.GapModule)},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeatureRoutingModule {
}
