import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OrderListComponent} from './components/order-list/order-list.component';
import { AuthenticationGuard } from '../../../core/guards/authentication.guard';

const routes: Routes = [
  {
    path: 'order-list',
    component: OrderListComponent,canActivate:[AuthenticationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderListRoutingModule { }
