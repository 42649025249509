<div class="d-flex justify-content-between">
  <app-sidebar></app-sidebar>
  <div class="content  m-t-c">
    <div class="container-fluid">
      <div class="row">
        <p class="p-0 fs-20 font-weight-heavy">قیمت لحظه ای ارزهای دیجیتال</p>
        <div class="search-div">
          <input placeholder="جستجو بر اساس نام و یا نماد اختصاری" [(ngModel)]="searchKey" (keyup)="searchEvent()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Search" transform="translate(2 2)">
              <ellipse id="Ellipse_746" cx="8.599" cy="8.653" rx="8.599" ry="8.653" transform="translate(0 0)"
                       fill="rgba(255,255,255,0.62)"/>
              <path id="Path_34202"
                    d="M3.278,4.577a1.321,1.321,0,0,1-.889-.385L.352,1.812A1.088,1.088,0,0,1,.272.29h0A.974.974,0,0,1,1.66.29L4.221,2.339a1.348,1.348,0,0,1,.291,1.431,1.335,1.335,0,0,1-1.18.851Z"
                    transform="translate(15.397 15.379)" fill="rgba(255,255,255,0.62)" opacity="0.4"/>
            </g>
          </svg>
        </div>
        <div class="card card-gradient px-0">
          <div class="card-body px-border-gradient0 position-relative p-0">
            <img class="buy-sell-card-avatar" src="assets/images/live-price-avatar.png"
                 alt="card-avatar">
            <div class="col-12 overflow-auto">
              <div class="table-head">
                <div class="th">
                  <div class="font-weight-bold fs-20">#</div>
                  <div class="font-weight-bold fs-20 text-white">ارز دیجیتال</div>
                  <div class="font-weight-bold fs-20 text-white text-center">قیمت خرید</div>
                  <div class="font-weight-bold fs-20 text-white text-center">قیمت فروش</div>
                  <div class="font-weight-bold fs-20 text-white text-center">قیمت جهانی</div>
                  <div class="font-weight-bold fs-20 text-white text-center">تغییرات</div>
                  <!--                  <div class="font-weight-bold fs-20 text-white text-center">شبکه</div>-->
                </div>
              </div>
              <div class="border-gradient"></div>

              <div class="table-body" dir="ltr">
                <div *ngIf="loading">
                  <div class="tr" dir="rtl" *ngFor="let _ of skeleton">
                    <div class="font-weight-bold fs-20">
                      <ngx-skeleton-loader class="list-num-loading"
                                           animation="progress-dark"
                                           count="1" appearance="line"></ngx-skeleton-loader>
                    </div>
                    <div class="font-weight-bold fs-20 text-white">
                      <div class="d-flex align-items-center flex-row justify-content-start">
                        <div class="position-relative ms-3">
                          <ngx-skeleton-loader class="list-icon-loading"
                                               animation="progress-dark"
                                               count="1" appearance="circle"></ngx-skeleton-loader>
                        </div>
                        <div class="coin-name-container">
                          <div class="fs-18 font-weight-bold">
                            <ngx-skeleton-loader class="list-text-loading"
                                                 animation="progress-dark"
                                                 count="1" appearance="line"></ngx-skeleton-loader>
                          </div>
                          <div class="fs-18 font-weight-bold">
                            <ngx-skeleton-loader class="list-text-loading"
                                                 animation="progress-dark"
                                                 count="1" appearance="line"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="font-weight-bold fs-20 text-white text-center">
                      <ngx-skeleton-loader class="list-text-loading"
                                           animation="progress-dark"
                                           count="1" appearance="line"></ngx-skeleton-loader>
                    </div>
                    <div class="font-weight-bold fs-20 text-white text-center">
                      <ngx-skeleton-loader class="list-text-loading"
                                           animation="progress-dark"
                                           count="1" appearance="line"></ngx-skeleton-loader>
                    </div>
                    <div class="font-weight-bold fs-20 text-white text-center">
                      <ngx-skeleton-loader class="list-text-loading"
                                           animation="progress-dark"
                                           count="1" appearance="line"></ngx-skeleton-loader>
                    </div>
                    <div class="font-weight-bold fs-20 text-white text-center">
                      <ngx-skeleton-loader class="list-text-loading"
                                           animation="progress-dark"
                                           count="1" appearance="line"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>


                <div class="tr" dir="rtl" *ngFor="let currency of filtered; let i = index" >
                  <div
                    class="font-weight-bold fs-20">  {{i + 1 | formatNumber: 'toPersian'}}</div>
                  <div class="font-weight-bold fs-20 text-white">
                    <div class="d-flex align-items-center flex-row justify-content-start">
                      <div class="position-relative ms-3">
                        <img class="coin-icon" [src]="currency?.current?.iconPath" alt="btc">
                        <div class="coin-icon-back" [style]="{'background-image': 'url(' + currency?.current?.iconPath + ')'}"></div>
                      </div>
                      <div class="coin-name-container">
                        <div class="fs-18 font-weight-bold">{{currency?.current?.name}}</div>
                        <div class="fs-18 font-weight-bold">{{currency?.current?.id}} - {{currency?.current?.id}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="font-weight-bold fs-20 text-white text-center">
                    <div class="price-holder">
                      <p
                        class="fs-20 font-weight-regular">{{(currency?.current?.irrBuyPrice) |
                        number:'1.0-0'  | formatMoney | formatNumber:'toPersian'}}</p>
                      <p class="fs-14 font-weight-light me-2">تومان</p>
                    </div>
                  </div>
                  <div class="font-weight-bold fs-20 text-white text-center">
                    <div class="price-holder">
                      <p
                        class="fs-20 font-weight-regular">{{(currency?.current?.irrSellPrice) |
                        number:'1.0-0'  | formatMoney | formatNumber:'toPersian'}}</p>
                      <p class="fs-14 font-weight-light me-2">تومان</p>
                    </div>
                  </div>
                  <div class="font-weight-bold fs-20 text-white text-center">
                    <div class="price-holder">
                      <p
                        class="fs-20 font-weight-regular">{{(currency?.current?.usdcPrice) | formatMoney | formatNumber:'toPersian'}}</p>
                      <p class="fs-14 font-weight-light me-2">تتر</p>
                    </div>
                  </div>
                  <div class="font-weight-bold fs-20 text-white text-center">
                    <div dir="ltr" *ngIf="currency?.current?.priceDiff !== undefined" class="fs-16"
                         [ngClass]="currency?.current?.priceDiff < 0 ? 'negative-value' :'positive-value'">
                      ٪{{currency?.current?.priceDiff | number:'1.0-1'}}
                    </div>
                  </div>
                </div>
                <div class="row text-center" *ngIf="filtered.length===0 && !loading">
                  <p class="fs-20 font-weight-heavy text-white ">موردی یافت نشد</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
