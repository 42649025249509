<router-outlet></router-outlet>
<div class="d-flex justify-content-between">
  <app-sidebar></app-sidebar>
  <div class="content  m-t-c">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-xxl-5">
          <p class="fw-900 fs-20">از اینجا میتونی خرید و فروش انجام بدی!</p>
          <div class="card card-gradient">
            <div class="card-body position-relative">
              <img class="buy-sell-card-avatar" src="assets/images/buy-sell-pic.png" alt="card-avatar">
              <div class="d-flex justify-content-center mb-3">
                <div class="inner-card button-group">
                  <div [class.left]="this.order.side === 'sell'" [class.right]="this.order.side === 'buy'"
                       class="btn-primary placeholder left"></div>
                  <div (click)="this.order.side = 'buy'" class="btn">خرید</div>
                  <div (click)="this.order.side = 'sell'" class="btn">فروش</div>
                </div>
              </div>
              <div class="d-flex justify-content-center mb-3">
                <ng-select class="select-coin ms-3" [searchable]="false" [clearable]="false"
                           [(ngModel)]="order.currency">
                  <ng-option *ngFor="let coin of coins" [value]="coin">
                    <div class="d-flex w-100 align-items-center">
                      <img class="coin-icon" [src]="coin.iconPath" alt="btc">
                      <span>{{coin.name}} ({{coin.id}})</span>
                    </div>
                  </ng-option>
                </ng-select>
                <div class="inner-card coin-amount">
                  <input type="text" appLimitDecimalNumber name="amount" [(ngModel)]="order.amount">
                  <span>عدد</span>
                </div>
              </div>
              <div class="d-flex justify-content-center mb-4">
                <div class="inner-card total-price justify-content-center">
                  {{(((this.order.side === 'sell' ? order.currency?.irrSellPrice : order.currency?.irrBuyPrice)) * order.amount) |
                  number:'1.0-0' | formatNumber:'toPersian'}}
                  <span class="me-2">تومان</span>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" [disabled]="orderLoading" (click)="createOrder()"
                        class="btn btn-primary d-flex align-items-center">
                  <i *ngIf="orderLoading" class="spinner d-flex justify-content-center align-items-center ms-2">
                    <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="currentColor"
                            d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
                    </svg>
                  </i> درخواست {{this.order.side === 'sell' ? 'فروش' : 'خرید'}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xxl-7">
          <p class="fw-900 fs-20">آخرین تغییرات ارز هارو میتونی بیینی!</p>
          <div class="card">
            <div class="card-body">
              <div class="chart-container">
                <app-charts></app-charts>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-12 col-xxl-8">
          <div class="row">
            <div class="col-12">
              <p class="fw-900 fs-20">ازینجا میتونی ارز هارو بهم تبدیل کنی!</p>
              <div class="card">
                <div class="card-body position-relative">
                  <div class="row g-xxl-0 d-flex justify-content-between align-items-center py-3 p-md-3">
                    <div class="col-7 col-md-6 mb-2 mb-xxl-0 col-xxl-3 d-flex justify-content-center align-self-stretch">
                      <ng-select [searchable]="false" [clearable]="false" [(ngModel)]="rightCoin"
                                 (ngModelChange)="calculator(rightCoinPrice,'right')"
                                 #selectComponent>
                        <ng-option *ngFor="let coin of coins" [value]="coin">
                          <div class="d-flex w-100 align-items-center">
                            <img class="coin-icon" [src]="coin.iconPath" alt="btc">
                            <span>{{coin.name}} ({{coin.id}})</span>
                          </div>
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="col-5 col-md-6 mb-2 mb-xxl-0 col-xxl-2 d-flex justify-content-center align-self-stretch">
                      <div class="inner-card coin-amount align-self-stretch">
                        <input type="number" [ngModel]="rightCoinPrice" (ngModelChange)="calculator($event,'right')">
                        <span>عدد</span>
                      </div>
                    </div>
                    <div class="col-12 col-xxl-1 d-flex justify-content-center">
                      <svg (click)="swapCoin()" class="coin-swap" xmlns="http://www.w3.org/2000/svg" width="50"
                           height="50" viewBox="0 0 120 120">
                        <defs/>
                        <defs>
                          <filter id="a" width="103.62" height="104.12" x="16.38" y="0" filterUnits="userSpaceOnUse">
                            <feOffset/>
                            <feGaussianBlur result="b" stdDeviation="15"/>
                            <feFlood flood-color="#845ea5"/>
                            <feComposite in2="b" operator="in"/>
                            <feComposite in="SourceGraphic"/>
                          </filter>
                          <filter id="b" width="71.22" height="63.57" x="31.48" y="36.16" filterUnits="userSpaceOnUse">
                            <feOffset/>
                            <feGaussianBlur result="d" stdDeviation="10"/>
                            <feFlood flood-color="#845ea5"/>
                            <feComposite in2="d" operator="in"/>
                            <feComposite in="SourceGraphic"/>
                          </filter>
                          <filter id="c" width="103.62" height="104.12" x="0" y="15.88" filterUnits="userSpaceOnUse">
                            <feOffset/>
                            <feGaussianBlur result="f" stdDeviation="15"/>
                            <feFlood flood-color="#845ea5"/>
                            <feComposite in2="f" operator="in"/>
                            <feComposite in="SourceGraphic"/>
                          </filter>
                        </defs>
                        <path fill="#845ea5"
                              d="M58.52 52.06a1.5 1.5 0 00-1.58-1.4l-7.63-.39a1.91 1.91 0 00-2.02 1.79 1.9 1.9 0 002.02 1.78l7.63-.38a1.5 1.5 0 001.58-1.4"
                              opacity=".4"/>
                        <g filter="url(#a)">
                          <path fill="#845ea5"
                                d="M73.88 53.97a7.27 7.27 0 00.66-.65 1.93 1.93 0 00.46-1.25 2.01 2.01 0 00-.5-1.34l-.64-.6a30 30 0 00-7.9-4.74 7.1 7.1 0 00-1.6-.39 2.96 2.96 0 00-1.22.26 2.34 2.34 0 00-1.08 1.06 10.19 10.19 0 00-.35 1.25 24.32 24.32 0 00-.33 4.48 28.22 28.22 0 00.3 4.34 8.78 8.78 0 00.46 1.56 2.49 2.49 0 002.14 1.17h.08a7.44 7.44 0 001.8-.48 29.98 29.98 0 007.72-4.67"/>
                        </g>
                        <g filter="url(#b)">
                          <path fill="#845ea5"
                                d="M61.49 67.94a1.5 1.5 0 001.58 1.4l7.63.38a1.9 1.9 0 002.01-1.78 1.91 1.91 0 00-2.01-1.79l-7.64.39a1.5 1.5 0 00-1.58 1.4"
                                opacity=".4"/>
                        </g>
                        <g filter="url(#c)">
                          <path fill="#845ea5"
                                d="M56.86 74.74a2.35 2.35 0 001.08-1.06 10.27 10.27 0 00.35-1.25 24.32 24.32 0 00.33-4.48 28.22 28.22 0 00-.29-4.34 8.96 8.96 0 00-.46-1.56 2.49 2.49 0 00-2.15-1.17h-.08a7.34 7.34 0 00-1.8.48 29.9 29.9 0 00-7.72 4.67 7.08 7.08 0 00-.66.65 1.91 1.91 0 00-.46 1.25 2.02 2.02 0 00.5 1.34l.63.6a30.06 30.06 0 007.9 4.73 7.04 7.04 0 001.6.4 2.96 2.96 0 001.22-.26"/>
                        </g>
                      </svg>
                    </div>
                    <div class="col-7 col-md-6 mt-2 mt-xxl-0 col-xxl-3 d-flex justify-content-center align-self-stretch">
                      <ng-select [searchable]="false"
                                 [clearable]="false"
                                 [(ngModel)]="leftCoin"
                                 (ngModelChange)="calculator(rightCoinPrice,'right')"
                                 #selectComponent2>
                        <ng-option *ngFor="let coin of coins" [value]="coin">
                          <div class="d-flex w-100 align-items-center">
                            <img class="coin-icon" [src]="coin.iconPath" alt="btc">
                            <span>{{coin.name}} ({{coin.id}})</span>
                          </div>
                        </ng-option>
                      </ng-select>
                    </div>
                    <div class="col-5  col-md-6 mt-2 mt-xxl-0 col-xxl-2 d-flex justify-content-center align-self-stretch">
                      <div class="inner-card coin-amount align-self-stretch">
                        <input type="number" disabled [ngModel]="leftCoinPrice"
                               (ngModelChange)="calculator($event,'left')">
                        <span class="text-disabled">عدد</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="row mt-5 position-relative" [class.zIndex]="selectComponent.isOpen || selectComponent2.isOpen">
            <div class="col-12">
              <div class="d-flex justify-content-between align-items-md-center flex-column flex-md-row" >
                <p class="fw-900 fs-20">ازینجا میتونی تاریخچه سفارشاتت رو ببینی!</p>
                <button routerLink="/order-list" class="btn btn-secondary mb-11 fs-14 font-weight-bold">مشاهده همه
                </button>
              </div>
              <div class="card">
                <div class="card-body">
                  <app-orders (showOrder)="showOrder($event)" #ordersComponent></app-orders>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5 mt-xxl-0 col-xxl-4">
          <p class="fw-900 fs-20">آخرین مطالب اعضا رو اینجا ببین!</p>
          <div class="card w-100">
            <div class="card-body position-relative">
              <div class="d-flex">
                <img class="users-comment-card-avatar" src="assets/images/background2.png" alt="background2">
              </div>
              <app-discussion [forums]="forums"></app-discussion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal>
  <app-pre-buy-invoice *ngIf="modalContent === 'NewOrder'" [type]="order?.side"></app-pre-buy-invoice>
  <app-purchase-order-details *ngIf="modalContent === 'purchase-order-detail'"></app-purchase-order-details>
</app-modal>
