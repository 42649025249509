import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderService} from 'src/app/shared/services/order.service';
import {OrderDetail} from 'src/app/shared/models/order-detail.model';
import {SubSink} from 'subsink';
import {Modal} from 'bootstrap';
import {ModalService} from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, OnDestroy {

  orders: OrderDetail[];
  sub = new SubSink();
  lastPage = false;
  page = 1;
  limit = 10;
  skeleton = new Array(8);
  orderModal: Modal;

  constructor(private orderService: OrderService, private modalService: ModalService) {
  }

  getOrders(): void {
    this.orderService.getOrder().subscribe((orders: OrderDetail[]) => {
      this.orders = orders.reverse();

    });
  }

  onNextPage(): void {
    this.getOrders();
  }

  ngOnInit(): void {
    this.getOrders();
    this.sub.sink = this.modalService.modalStream.subscribe((modal) => {
      this.orderModal = modal;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  showModal(order: OrderDetail): void {
    this.orderService.orderDetailStream.next(order);
    this.orderModal.show();
  }
}
