import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {FeatureRoutingModule} from 'src/app/feature/feature-routing.module';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {CoreModule} from 'src/app/core/core.module';
import {BrowserModule} from '@angular/platform-browser';
import {SharedModule} from 'src/app/shared/shared.module';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {ChartsModule} from 'ng2-charts';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ChartsComponent} from './components/charts/charts.component';
import {DiscussionComponent} from './components/discussion/discussion.component';
import {OrdersComponent} from './components/orders/orders.component';
import {LivePriceModule} from './modules/live-price/live-price.module';
import {ProfileModule} from './modules/profile/profile.module';
import {CreateOrderComponent} from 'src/app/feature/components/create-order/create-order.component';
import {AboutUsModule} from './modules/about-us/about-us.module';
import {FaqModule} from './modules/faq/faq.module';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {CurrencyWalletPipe} from './pipes/currency-wallet.pipe';
import {DateFormatPipe} from 'src/app/shared/pipes/jdate.pipe';
import {FormatNumberPipe} from 'src/app/shared/components/pipes/format-number.pipe';
import {OrderListModule} from './modules/order-list/order-list.module';

@NgModule({
  declarations: [
    DashboardComponent,
    ChartsComponent,
    DiscussionComponent,
    OrdersComponent,
    CreateOrderComponent,
    CurrencyWalletPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    FeatureRoutingModule,
    FormsModule,
    NgSelectModule,
    SharedModule,
    ChartsModule,
    CarouselModule,
    LivePriceModule,
    ProfileModule,
    AboutUsModule,
    NgxSkeletonLoaderModule,
    AboutUsModule,
    FaqModule,
    OrderListModule
  ],
  providers: [
    DateFormatPipe,
    FormatNumberPipe,
    DecimalPipe
  ],
  bootstrap: [DashboardComponent]
})
export class FeatureModule {
}
