import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment-jalaali';

moment.loadPersian({dialect: 'persian-modern'});


@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value) {
      // return moment.utc(value, 'YYYY-MM-DD HH:mm').local().format(args[0]);
      return moment(value, 'YYYY-MM-DD HH:mm').local().format(args[0]);
    }
    else {
      return '';
    }
  }

}
