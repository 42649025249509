import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ValidationErrorComponent} from 'src/app/shared/components/validation-error/validation-error.component';
import {FormatNumberPipe} from 'src/app/shared/components/pipes/format-number.pipe';
import {NumberLimitDirective} from 'src/app/shared/directives/number-limit.directive';
import {FormatMoneyPipe} from 'src/app/shared/components/pipes/format-money.pipe';
import {SearchPipe} from './components/pipes/search.pipe';
import {SafeHtmlPipe} from 'src/app/shared/pipes/safe-html.pipe';
import {LimitDecimalNumberDirective} from 'src/app/shared/directives/limit-decimal-number.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DateFormatPipe} from './pipes/jdate.pipe';
import {ModalComponent} from './components/modal/modal.component';
import {FileComponent} from 'src/app/shared/components/file/file.component';
import {PurchaseOrderDetailsComponent} from './components/purchase-order-details/purchase-order-details.component';
import { CheckAccessDirective } from './directives/checkAccess.directive';

@NgModule({
  declarations: [
    ValidationErrorComponent,
    FormatNumberPipe,
    NumberLimitDirective,
    CheckAccessDirective,
    FormatMoneyPipe,
    DateFormatPipe,
    SearchPipe,
    SafeHtmlPipe,
    LimitDecimalNumberDirective,
    ModalComponent,
    FileComponent,
    PurchaseOrderDetailsComponent
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ValidationErrorComponent,
    FormatNumberPipe,
    NumberLimitDirective,
    CheckAccessDirective,
    FormatMoneyPipe,
    DateFormatPipe,
    SearchPipe,
    SafeHtmlPipe,
    LimitDecimalNumberDirective,
    ModalComponent,
    FileComponent,
    DateFormatPipe,
    PurchaseOrderDetailsComponent
  ]
})
export class SharedModule {
}
