import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpError } from '../models/http-error.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import * as Cookie from 'js-cookie';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  baseUrl = 'https://api.zararz.com';

  constructor(private http: HttpClient, public router: Router) {
  }

  public request(
    method: string,
    url: string,
    data = null,
    contentType = 'application/json'
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      hl: 'fa',
      'Content-Type': contentType,
    });
    if (data) {
      return this.http[method](`${this.baseUrl}${url}`, data, {
        headers
      }).pipe(catchError(this.authentication.bind(this)));
    }
    return this.http[method](`${this.baseUrl}${url}`, {
      headers
    }).pipe(catchError(this.authentication.bind(this)));
  }

  authentication(error: HttpError): Observable<Error> {
    if (error.status === 401) {
      localStorage.clear();
      Cookie.remove('token');
      // this.router.navigateByUrl('/authentication/sign-in');
    }
    return throwError(error);
  }
}
