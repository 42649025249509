import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderListRoutingModule } from './order-list-routing.module';
import { OrderListComponent } from './components/order-list/order-list.component';
import {CoreModule} from 'src/app/core/core.module';
import {SharedModule} from 'src/app/shared/shared.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';

@NgModule({
  declarations: [OrderListComponent],
  imports: [
    CommonModule,
    OrderListRoutingModule,
    CoreModule,
    SharedModule,
    InfiniteScrollModule,
    NgxSkeletonLoaderModule,
  ]
})
export class OrderListModule { }
