import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Currency} from 'src/app/shared/models/currency.model';
import {AlertService} from 'src/app/shared/services/alert.service';
import {Alert} from 'src/app/shared/models/alert.model';
import {HttpError} from 'src/app/shared/models/http-error.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {

  @Input() cryptoList: Currency[] = [];
  @Input() currency: Currency;

  @Output() refreshAlertList = new EventEmitter<void>();

  alert: Alert = new Alert();
  triggerPrice!: string;
  loading = false;
  Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  constructor(private alertService: AlertService) {

  }

  createAlert(): void {
    this.alert.currencyId = this.currency.id;
    this.alert.irrPrice = +this.triggerPrice?.replace(/\,/g, '');
    this.loading = true;
    this.alertService.createAlert(this.alert).subscribe(() => {
      this.loading = false;
      document.getElementById('dismissModal').click();
      this.alert = new Alert();
      this.triggerPrice = undefined;
      this.Toast.fire({icon: 'success', text: 'اعلان با موفقیت ایجاد شد.'});
      this.refreshAlertList.emit();
    }, (response: HttpError) => {
      if (response.status === 422) {
        if (response.validationError?.irrPrice?.length > 0) {
          this.Toast.fire({icon: 'error', text: response.validationError.irrPrice[0]});
        }
        this.loading = false;
      } else {
        this.Toast.fire({icon: 'error', text: response.error});
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
  }

}
