export class Card {
    id: number;
    card: string;
    cardHolderName: string;
    status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
    errors: any = {};
    bankName ?: string;
    cardSplit: string[];

    constructor(input: any = {}) {
        this.card = input.card;
        this.cardHolderName = input.card_holder_name;
        this.status = input.status;
        this.id = input.id;
        if (this.card) {
            this.cardSplit = this.card.match(/.{1,4}/g);
        }
    }
}
