import { Directive, ElementRef, HostListener } from '@angular/core';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../services/authentication.service';

@Directive({
  selector: '[appCheckAccess]'
})
export class CheckAccessDirective {
  private el: HTMLInputElement;
  toast = Swal.mixin({
    toast: false,
    position: 'center',
    showConfirmButton: true,
    allowEnterKey: true,
    allowEscapeKey: true,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-primary',
    },
    confirmButtonText: 'متوجه شدم',
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  constructor(private elementRef: ElementRef, private authenticationService: AuthenticationService) {
    this.el = this.elementRef.nativeElement;
  }
  @HostListener("click", ["$event"])
  public onClick(event: any): void {
    if (!this.authenticationService.checkUserSignedIn()) {
      this.authAlert();
    }
  }
  authAlert(): void {
    this.toast.fire({ icon: 'info', text: `لطفا وارد سیستم شوید.` });
  }
}
