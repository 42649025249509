<div class=" w-100 p-4 d-lg-none mb-5 menu">
  <div class="d-flex justify-content-between w-100 align-items-center">
      <div>
          <svg (click)="showMenu()" width="19" height="15" viewBox="0 0 19 15" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M7 1L18 1" stroke="white" stroke-width="2" stroke-linecap="round" />
              <path d="M1 7H18" stroke="white" stroke-width="2" stroke-linecap="round" />
              <path d="M1 13.3633H18" stroke="white" stroke-width="2" stroke-linecap="round" />
          </svg>
      </div>
      
      <div *ngIf="!isSignedIn else avatar" class="login-btn-container">
        <button class="btn btn-primary" routerLink="/authentication" >ورود/ثبت‌نام</button>
      </div>
  </div>
</div>
<div class="sidebar d-lg-flex" [class]="show?'d-flex':'d-none '">
  <div class="sidebar-gradient"></div>
  <div [class]="show?'d-block':'d-none '" class="close" (click)="hideMenu()">
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L12 12M12 1L1 12" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <div class="sidebar-items">
    <ul>
      <li routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="hideMenu()">
        <svg id="Iconly_Bulk_Category" data-name="Iconly/Bulk/Category" xmlns="http://www.w3.org/2000/svg" width="25"
             height="25" viewBox="0 0 25 25">
          <g id="Category" transform="translate(0 0)">
            <path routerLinkActive="fill-0"  [routerLinkActiveOptions]="{exact: true}" id="Fill_1" data-name="Fill 1"
                  d="M3.173,0H7.406a3.186,3.186,0,0,1,3.173,3.2V7.468a3.186,3.186,0,0,1-3.173,3.2H3.173A3.186,3.186,0,0,1,0,7.468V3.2A3.186,3.186,0,0,1,3.173,0"
                  transform="translate(14.421 0)" fill="#fff"/>
            <path routerLinkActive="fill-2"  [routerLinkActiveOptions]="{exact: true}" id="Combined_Shape" data-name="Combined Shape"
                  d="M17.594,25a3.186,3.186,0,0,1-3.173-3.2V17.532a3.186,3.186,0,0,1,3.173-3.2h4.233A3.186,3.186,0,0,1,25,17.532V21.8A3.186,3.186,0,0,1,21.827,25ZM3.174,25A3.187,3.187,0,0,1,0,21.8V17.532a3.187,3.187,0,0,1,3.174-3.2H7.406a3.186,3.186,0,0,1,3.172,3.2V21.8A3.186,3.186,0,0,1,7.406,25Zm0-14.331A3.187,3.187,0,0,1,0,7.468V3.2A3.186,3.186,0,0,1,3.174,0H7.406a3.185,3.185,0,0,1,3.172,3.2V7.468a3.186,3.186,0,0,1-3.172,3.2Z"
                  transform="translate(0 0)" fill="#777779"/>
          </g>
        </svg>
        صفحه اصلی
      </li>
      <li routerLink="/live-price" routerLinkActive="active" (click)="hideMenu()">
        <svg id="Iconly_Bulk_Chart" data-name="Iconly/Bulk/Chart" xmlns="http://www.w3.org/2000/svg" width="30"
             height="30" viewBox="0 0 30 30">
          <g id="Chart" transform="translate(2.5 2.5)">
            <path routerLinkActive="fill-2" id="Fill_1" data-name="Fill 1"
                  d="M18.344,0H6.667C2.411,0,0,2.411,0,6.667V18.333C0,22.589,2.411,25,6.667,25H18.344C22.6,25,25,22.589,25,18.333V6.667C25,2.411,22.6,0,18.344,0"
                  fill="#fff" opacity="0.4"/>
            <path routerLinkActive="fill-2" id="Fill_4" data-name="Fill 4"
                  d="M1.033,0A1.041,1.041,0,0,0,0,1.044V9.633a1.039,1.039,0,0,0,2.078,0V1.044A1.043,1.043,0,0,0,1.033,0"
                  transform="translate(5.678 9.211)" fill="#fff"/>
            <path routerLinkActive="fill-2" id="Fill_6" data-name="Fill 6"
                  d="M1.033,0A1.041,1.041,0,0,0,0,1.044V13.733a1.039,1.039,0,0,0,2.078,0V1.044A1.043,1.043,0,0,0,1.033,0"
                  transform="translate(11.511 5.111)" fill="#fff"/>
            <path routerLinkActive="fill-2" id="Fill_8" data-name="Fill 8"
                  d="M1.044,0A1.043,1.043,0,0,0,0,1.044V5.1a1.039,1.039,0,0,0,2.078,0V1.044A1.041,1.041,0,0,0,1.044,0"
                  transform="translate(17.255 13.745)" fill="#fff"/>
          </g>
        </svg>
        قیمت لحظه‌ای
      </li>
      <li routerLink="/faq" routerLinkActive="active" (click)="hideMenu()">
        <svg id="Iconly_Bulk_Document" data-name="Iconly/Bulk/Document" xmlns="http://www.w3.org/2000/svg" width="30"
             height="30" viewBox="0 0 22.5 25">
          <g id="Document">
            <path id="Path" routerLinkActive="fill-2"
                  d="M6.011,0H16.487c3.8,0,6.013,2.225,6.013,6.037V18.95c0,3.875-2.212,6.05-6.013,6.05H6.011C2.15,25,0,22.825,0,18.95V6.037C0,2.225,2.15,0,6.011,0"
                  fill="#fff" opacity="0.4"/>
            <path id="Combined_Shape" data-name="Combined Shape" routerLinkActive="fill-2"
                  d="M.149.458a1,1,0,0,0,0,1.05.982.982,0,0,0,.938.462h9.8a.981.981,0,0,0,.875-.988.978.978,0,0,0-.875-.974h-9.8A.915.915,0,0,0,.965,0,.979.979,0,0,0,.149.458ZM1.088,5.72a.976.976,0,0,0,0,1.952h9.8a.976.976,0,0,0,0-1.952Zm0,5.651a.975.975,0,1,0,0,1.95v.012H4.824a.981.981,0,0,0,0-1.962Z"
                  transform="translate(17.237 19.145) rotate(180)" fill="#fff"/>
          </g>
        </svg>
        سوالات متداول
      </li>
      <li routerLink="/about-us" routerLinkActive="active" (click)="hideMenu()">
        <svg id="Iconly_Bulk_Info_Circle" data-name="Iconly/Bulk/Info Circle" xmlns="http://www.w3.org/2000/svg"
             width="30" height="30" viewBox="0 0 30 30">
          <path routerLinkActive="fill-2" id="Fill_1" data-name="Fill 1" d="M25,12.5A12.5,12.5,0,1,1,12.5,0,12.5,12.5,0,0,1,25,12.5"
                transform="translate(2.5 2.5)" fill="#fff" opacity="0.4"/>
          <path routerLinkActive="fill-2" id="Combined_Shape" data-name="Combined Shape"
                d="M.007,10.585a1.1,1.1,0,1,1,1.1,1.093A1.093,1.093,0,0,1,.007,10.585ZM0,6.618V1.094a1.094,1.094,0,0,1,2.187,0V6.618A1.094,1.094,0,1,1,0,6.618Z"
                transform="translate(13.9 9.17)" fill="#fff"/>
        </svg>
        درباره زرارز
      </li>
      <li class="d-lg-none" *ngIf="isSignedIn"  routerLink="/"  (click)="signOut();hideMenu()">
        <svg  width="30" height="30" viewBox="0 0 30 30" class="svg-icon" style="vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M768 106V184c97.2 76 160 194.8 160 328 0 229.6-186.4 416-416 416S96 741.6 96 512c0-133.2 62.8-251.6 160-328V106C121.6 190.8 32 341.2 32 512c0 265.2 214.8 480 480 480s480-214.8 480-480c0-170.8-89.6-321.2-224-406z" fill="" /><path d="M512 32c-17.6 0-32 14.4-32 32v448c0 17.6 14.4 32 32 32s32-14.4 32-32V64c0-17.6-14.4-32-32-32z" fill="#fff" /></svg>
        خروج
      </li>
    </ul>
  </div>
  <div [class.invisible]="isProfilePage" class="profile d-none d-lg-block" >
    <div *ngIf="isSignedIn && user?.authenticated !== 'ACCEPTED'" class="not-authenticated">
      احراز هویت تکمیل نشده است!
    </div>
    <div class="profile-card" [class.user-info]="isSignedIn">
      <ng-container *ngIf="!isSignedIn;else userTemplate">
        <p>برای استفاده از تمام امکانات پروفایل خودت رو بساز!</p>
        <div class="login-btn-container">
          <button class="btn btn-primary" routerLink="/authentication" >ورود/ثبت‌نام</button>
        </div>
        <div class="login-image"></div>
      </ng-container>
      <ng-template #userTemplate>
        <ng-container *ngIf="user">
          <div class="d-flex justify-content-center position-relative mt-3">
            <svg *ngIf="user?.authenticated !== 'ACCEPTED'"  style="position: absolute;top: 37%;left: 66%;z-index: 10"  xmlns="http://www.w3.org/2000/svg" width="17.499" height="17.499" viewBox="0 0 17.499 17.499">
              <g id="Group_390" data-name="Group 390" transform="translate(-1796.75 -835.654)">
                <g id="Iconly_Bulk_Discount" data-name="Iconly/Bulk/Discount" transform="translate(1796.75 835.654)">
                  <path id="Fill_1" data-name="Fill 1"
                        d="M8.749,17.5a2.629,2.629,0,0,1-1.858-.766l-.639-.639a1.316,1.316,0,0,0-.931-.384h-.9a2.638,2.638,0,0,1-2.635-2.635v-.9a1.32,1.32,0,0,0-.385-.932l-.628-.627a2.638,2.638,0,0,1-.01-3.725l.639-.64a1.316,1.316,0,0,0,.384-.931v-.9A2.638,2.638,0,0,1,4.422,1.788h.9A1.308,1.308,0,0,0,6.253,1.4L6.882.775a2.637,2.637,0,0,1,3.725-.01l.639.639a1.31,1.31,0,0,0,.93.384h.9A2.638,2.638,0,0,1,15.71,4.424v.9a1.32,1.32,0,0,0,.385.932l.628.628a2.633,2.633,0,0,1,.017,3.716c-.009.009-.017.018-.025.026l-.621.621a1.316,1.316,0,0,0-.384.931v.9a2.638,2.638,0,0,1-2.635,2.635h-.9a1.317,1.317,0,0,0-.931.385l-.629.628a2.624,2.624,0,0,1-1.866.775"
                        fill="#fff" style="fill: #707070;"></path>
                  <path id="Combined_Shape" data-name="Combined Shape"
                        d="M.005,8.468a.878.878,0,1,1,.879.875A.874.874,0,0,1,.005,8.468ZM0,5.295V.875a.875.875,0,0,1,1.75,0v4.42a.875.875,0,1,1-1.75,0Z"
                        transform="translate(7.75 3.586)" fill="#fff" opacity="0.879"></path>
                </g>
              </g>
            </svg>
            <svg *ngIf="user?.authenticated === 'ACCEPTED'" style="position: absolute;top: 37%;left: 66%;z-index: 10" id="Iconly_Bulk_Discount" data-name="Iconly/Bulk/Discount" xmlns="http://www.w3.org/2000/svg" width="17.499" height="17.499" viewBox="0 0 17.499 17.499">
              <path id="Fill_1" data-name="Fill 1" d="M8.749,17.5a2.629,2.629,0,0,1-1.858-.766l-.639-.639a1.316,1.316,0,0,0-.931-.384h-.9a2.638,2.638,0,0,1-2.635-2.635v-.9a1.32,1.32,0,0,0-.385-.932l-.628-.627a2.638,2.638,0,0,1-.01-3.725l.639-.64a1.316,1.316,0,0,0,.384-.931v-.9A2.638,2.638,0,0,1,4.422,1.788h.9A1.308,1.308,0,0,0,6.253,1.4L6.882.775a2.637,2.637,0,0,1,3.725-.01l.639.639a1.31,1.31,0,0,0,.93.384h.9a2.84,2.84,0,0,1,1.723.643,2.477,2.477,0,0,1,.912,1.993v.9a1.32,1.32,0,0,0,.385.932l.628.628a2.633,2.633,0,0,1,.017,3.716c-.009.009-.017.018-.025.026l-.621.621a1.316,1.316,0,0,0-.384.931v.9a2.638,2.638,0,0,1-2.635,2.635h-.9a1.317,1.317,0,0,0-.931.385l-.629.628a2.624,2.624,0,0,1-1.866.775" fill="#4a4750"/>
              <path id="Fill_4" data-name="Fill 4" d="M3.249,6.5a.872.872,0,0,1-.619-.256L.257,3.868A.875.875,0,0,1,1.493,2.629L3.249,4.385,7.377.257A.875.875,0,0,1,8.613,1.495L3.868,6.24a.872.872,0,0,1-.619.256" transform="translate(4.815 5.346)" fill="#7ceb91"/>
            </svg>




            <div class="avatar-holder d-flex align-items-center justify-content-center"
                 [style]="'border-color:' + user.avatar?.color">
              <div class="avatar-circle d-flex justify-content-center align-items-end position-relative"
                   [style]="'background:'+user.avatar?.color">
                <img *ngIf="user.avatar?.icon" width="65" height="65" [src]="user.avatar?.icon" alt="avatar">
                <div class="backdrop" [style]="'background:url('+user.avatar?.icon+')'"></div>

              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <p class="p-0 username">{{user?.firstName}} {{user?.lastName}}</p>
          </div>
          <div class="d-flex justify-content-center">
            <button routerLink="/profile" class="btn btn-primary mt-3">مشاهده پروفایل</button>
          </div>
          <div class="d-flex justify-content-center">
            <button (click)="signOut()" class="btn btn-exit  mt-3">خروج</button>
          </div>
        </ng-container>

      </ng-template>
    </div>

  </div>
</div>
<ng-template #avatar>
  <div class="avatar-holder-sm d-flex align-items-center justify-content-center"
  [style]="'border-color:' + user.avatar?.color" routerLink="/profile">
  <div class="avatar-circle d-flex justify-content-center align-items-end position-relative"
      [style]="'background:'+user.avatar?.color">
      <img *ngIf="user.avatar?.icon" width="32" height="32" [src]="user.avatar?.icon" alt="avatar">
      <div class="backdrop" [style]="'background:url('+user.avatar?.icon+')'"></div>
  </div>
</div>
</ng-template>
