<p class="fs-20 font-weight-heavy">اعلان های شما</p>
<div class="card notify-container">
  <div class="card-body p-2">
    <div class="flex-column d-flex   h-100" [class.justify-content-center]="alerts && alerts.length === 0">
      <ng-container *ngIf="alerts; else AlertSkeleton">
        <ng-container *ngIf="alerts.length == 0;else AlertsTemplate">
          <div class="row">
            <div class="col-12 py-5 d-flex flex-column">
              <b class="text-center mb-3">هیچ اعلانی ثبت نشده است</b>
              <p class="text-center mb-3">مدت زمان اعتبار هر اعلان، نا محدود است.</p>
            </div>
          </div>
        </ng-container>
        <ng-template #AlertsTemplate>
          <div *ngFor="let alert of alerts" class="over-card mb-3 py-1  w-100 px-2">
            <div class="row my-3">
              <div class="d-flex align-items-center justify-content-start">
                <img class="coin-icon" [src]="alert.currency.iconPath" alt="coin">
                <span class="ms-sm-2 fs-18 font-weight-regular">{{alert.currency.name}}</span>
                <span class="fs-14 font-weight-light">({{alert.currency.id}})</span>
              </div>
            </div>
            <div class="row my-3">
              <div class="d-flex align-items-center">
                <div class="fs-14 font-weight-bold ms-1">
                  قیمت {{alert.sideType === 'sell' ? 'خرید' : 'فروش'}}</div>
                <div class="border-div fs-14 font-weight-heavy" [ngClass]="alert.priceType === 'lower_than' ? 'lt-color': 'gt-color'">
                  <span>{{alert.priceType === 'lower_than' ? 'کمتر از' : 'بیشتر از'}}</span>
                </div>
                <div class="price-holder">
                  <p
                    class="fs-14 font-weight-regular me-1">{{alert.irrPrice | number:'1.0-0' | formatNumber:'toPersian'}}</p>
                  <p class="fs-12 font-weight-light me-2">تومان</p>
                </div>
              </div>
            </div>
            <div class="row justify-content-start">
              <div class="col-5">
                <button type="button" [disabled]="deleteAlertLoading && deletedAlert === alert" (click)="deleteAlert(alert)"
                        class="btn btn-edit fs-12 font-weight-bold mb-1">
                  <ng-container
                    *ngIf="deleteAlertLoading == false || deletedAlert !== alert else loadingSpinner">
                    لغو
                  </ng-container>
                  <ng-template #loadingSpinner>
                    <i class="spinner d-flex justify-content-center align-items-center ms-2">
                      <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                              d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
                      </svg>
                    </i>
                  </ng-template>
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #AlertSkeleton>
        <div class="over-card mb-3 py-1 px-3 w-100">
          <div class="row my-3">
            <div class="d-flex align-items-center justify-content-start">
              <ngx-skeleton-loader class="coin-icon" appearance="circle"
                                   animation="progress-dark"></ngx-skeleton-loader>
              <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
            </div>
          </div>
          <div class="row my-3">
            <div class="d-flex align-items-center fs-12">
              <ngx-skeleton-loader class="alert-content w-100" animation="progress-dark"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <div class="over-card mb-3 py-1 px-3 w-100">
          <div class="row my-3">
            <div class="d-flex align-items-center justify-content-start">
              <ngx-skeleton-loader class="coin-icon" appearance="circle"
                                   animation="progress-dark"></ngx-skeleton-loader>
              <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
            </div>
          </div>
          <div class="row my-3">
            <div class="d-flex align-items-center fs-12">
              <ngx-skeleton-loader class="alert-content w-100" animation="progress-dark"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary save-btn fs-18 font-weight-bold" data-bs-toggle="modal"
                data-bs-target="#notification-modal">ثبت اعلان
        </button>
      </div>
    </div>
  </div>
</div>
