import {Currency} from 'src/app/shared/models/currency.model';

export class Alert {
  id: number;
  currency: Currency;
  currencyId: string;
  sideType: string;
  priceType: 'greater_than' | 'lower_than';
  irrPrice: number;

  constructor(input: any = {}) {
    this.id = input.id;
    this.currency = new Currency(input.currency ?? {});
    this.sideType = input.side_type ?? 'buy';
    this.priceType = input.price_type ?? 'greater_than';
    this.irrPrice = input.irr_price;
  }
}
