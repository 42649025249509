import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {APIModel} from 'src/app/shared/models/response.model';
import {map} from 'rxjs/operators';

@Injectable()
export class ResponseToModelInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {

        event = event.clone({body: new APIModel(event.body)});

      }
      return event;
    }));
  }
}
