import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CoinService} from 'src/app/shared/services/coin-service';
import {SubSink} from 'subsink';
import {Currency} from 'src/app/shared/models/currency.model';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {GapModel} from 'src/app/shared/models/gap.model';
import {GapService} from 'src/app/shared/services/gap-service';
import {User} from 'src/app/shared/models/user.model';
import {ModalService} from 'src/app/shared/services/modal.service';
import {Modal} from 'bootstrap';
import {OrderService} from 'src/app/shared/services/order.service';
import {Order} from 'src/app/shared/models/order.model';
import {HttpError} from 'src/app/shared/models/http-error.model';
import Swal from 'sweetalert2';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {UserService} from 'src/app/shared/services/user.service';
import {OrderDetail} from 'src/app/shared/models/order-detail.model';
import {OrdersComponent} from 'src/app/feature/components/orders/orders.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  private sub = new SubSink();
  user: User = new User();
  isVerified = false;
  updateCoinList: number;
  isSignedIn = this.authenticationService.checkUserSignedIn();
  tradeValue = 1;
  rightCoinPrice = 0.4;
  leftCoinPrice: number;
  coins: Currency[] = [];
  coinSelectionBox = false;
  order: Order = new Order();
  rightCoin: Currency;
  leftCoin: Currency;
  forums: Array<GapModel>;
  orderModal: Modal;
  modalContent = 'NewOrder';
  orderLoading: boolean;
  toast;
  @ViewChild('ordersComponent') ordersComponent: OrdersComponent;


  constructor(
    private coinService: CoinService,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private orderService: OrderService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private gapService: GapService) {
  }


  getCoinList(): void {
    this.sub.sink = this.coinService.getListOfCoins().subscribe((coins) => {
      this.coins = coins;
      this.order.currency = this.rightCoin = this.coins.find(coin => coin.id === 'BTC');
      this.leftCoin = this.coins.find(coin => coin.id === 'LTC');
      this.calculator(this.rightCoinPrice, 'right');
    });

  }

  swapCoin(): void {
    [this.leftCoin, this.rightCoin] = [this.rightCoin, this.leftCoin];
    [this.rightCoinPrice, this.leftCoinPrice] = [this.leftCoinPrice, this.rightCoinPrice];
  }

  calculator($event: number, type: string): void {
    if (type === 'right') {
      this.rightCoinPrice = $event;
      this.leftCoinPrice = (this.rightCoinPrice * this.rightCoin.usdcPrice) / this.leftCoin.usdcPrice;
      this.leftCoinPrice = Math.round(this.leftCoinPrice * 100) / 100;
    }
    if (type === 'left') {
      this.leftCoinPrice = $event;
      this.rightCoinPrice = (this.leftCoinPrice * this.leftCoin.usdcPrice) / this.rightCoin.usdcPrice;
      this.rightCoinPrice = Math.round(this.rightCoinPrice * 100) / 100;

    }
  }

  showOrder(order: OrderDetail): void {
    this.modalContent = 'purchase-order-detail';
    this.orderService.orderDetailStream.next(order);
    this.orderModal?.show();
  }

  createOrder(): void {
    if (this.authenticationService.checkUserSignedIn() === false) {
      this.router.navigateByUrl('/authentication/sign-in');
      return;
    }
    this.orderLoading = true;
    this.modalContent = 'NewOrder';
    if (typeof this.order.amount !== 'number') {
      this.order.amount = +this.order.amount.replace(/\,/g, '');
    }
    this.order.price = this.order.side === 'sell' ? this.order.currency.irrSellPrice : this.order.currency.irrBuyPrice;
    this.order.currencyId = this.order.currency.id;
    this.sub.sink = this.orderService.createOrder(this.order).subscribe((orderDetail) => {
      orderDetail.order = this.order;
      this.orderService.orderDetailStream.next(orderDetail);
      this.orderModal?.show();
      this.ordersComponent.getOrders();
      this.orderLoading = false;
    }, (response: HttpError) => {
      if (response.status !== 422) {
        this.orderLoading = false;
        this.toast.fire({icon: 'error', text: response.error});
      }
    });

  }

  getOrderDetail(token: string): void {
    this.orderModal?.show();
    this.sub.sink = this.orderService.getOrderDetail(token).subscribe((orderDetail) => {
      this.modalContent = 'purchase-order-detail';
      this.orderService.orderDetailStream.next(orderDetail);
      this.orderModal.show();
    }, error => {
      if (error.status === 400) {
        this.toast.fire({icon: 'error', text: error?.error ?? 'خطایی در سامانه رخ داده است.'});
        // this.orderModal.show();
      }
    });
  }

  ngOnInit(): void {
    this.toast = Swal.mixin({
      toast: false,
      position: 'center',
      showConfirmButton: true,
      allowEnterKey: true,
      allowEscapeKey: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      confirmButtonText: 'متوجه شدم',
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
    this.getCoinList();
    this.sub.sink = this.route.queryParamMap.subscribe((params) => {
      // const verify = params.get('verify');
      const status = params.get('payment_status');
      const token = params.get('token');
      if (token) {
        if (status === 'FAILED') {
          this.toast.fire({icon: 'error', text: 'متاسفانه پرداخت شما ناموفق بود!'});
        } else {
          this.getOrderDetail(token);
        }
      }
    });
    this.sub.sink = this.modalService.modalStream.subscribe((modal) => {
      this.orderModal = modal;
    });
    this.sub.sink = this.gapService.getListOfGaps(1, 5, 'created_at', 'desc', '').subscribe((forums: GapModel[]) => {
      this.forums = forums;
    });
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
    clearInterval(this.updateCoinList);
  }


}
