import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpError} from '../../shared/models/http-error.model';
import {Utility} from './utility';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlingInterceptor implements HttpInterceptor {
  constructor() {
  }

  handleError(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    const httpError = new HttpError();
    httpError.status = error.status;
    if (httpError.status === 422) {
      httpError.validationError = Utility.camelize(error.error.errors);
    } else {
      httpError.error = error?.error?.message ?? 'خطایی در سامانه رخ داده است.';
    }
    return throwError(httpError);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(this.handleError)
      );
  }
}
