import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Utility} from './utility';
import {Observable} from 'rxjs';

@Injectable()
export class EncodeDataInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body) {
      let data = req.body;
      data.hl = 'fa';
      data = Utility.decamelize(data);
      let encodedData: string;
      if (req.headers.get('Content-Type') === 'application/x-www-form-urlencoded') {
        encodedData = Object.keys(data)
          .filter(k => data.hasOwnProperty(k))
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
          .join('&');
      } else {
        encodedData = data;
      }
      req = req.clone({
        body: encodedData,
      });
    }
    return next.handle(req);

  }
}
