import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CoinService} from 'src/app/shared/services/coin-service';
import {Currency} from 'src/app/shared/models/currency.model';
import {CoinChartModel} from 'src/app/shared/models/coin.chart.model';

@Component({
  selector: 'app-live-price',
  templateUrl: './live-price.component.html',
  styleUrls: ['./live-price.component.scss']
})
export class LivePriceComponent implements OnInit, AfterViewInit {

  history: CoinChartModel[] = [];
  filtered: CoinChartModel[] = [];
  searchKey = '';
  descScrollerValue = 0;
  skeleton = new Array(3);
  loading = false;

  constructor(private coinService: CoinService) {
  }

  ngOnInit(): void {
    this.getLivePrice();
  }

  getLivePrice(): void {
    this.loading = true;
    this.coinService.getCoinsHistory().subscribe((histories) => {
      this.history = histories;
      this.filtered = histories;
      console.log('this.filtered', this.filtered);
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
  }


  searchEvent(): void {
    this.filtered = this.history.filter(history => (history?.current?.id.toLowerCase().indexOf(this.searchKey.toLowerCase()) > -1 ||
      history?.current?.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) > -1
    ));
  }
}
