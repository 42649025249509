import {Utility} from 'src/app/core/interceptor/utility';

export class APIModel {
  status: string;
  data: any;
  message: string;
  token: string;

  constructor(response: any) {
    Object.assign(this, response);

  }
}
