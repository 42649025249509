import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatMoney'
})
export class FormatMoneyPipe implements PipeTransform {
  formatMoney(amount): string {
    return amount.toLocaleString('fa-IR', {maximumFractionDigits: 2});
  }

  transform(value: any, ...args: any[]): any {
    if (value) {
      return this.formatMoney(value);
    }
    return value;
  }

}
