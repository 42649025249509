import {Utility} from 'src/app/core/interceptor/utility';

const icons3D = {
  BTC: 'assets/images/Bitcoin_3d.png',
  LTC: 'assets/images/Litecoin_3d.png',
  ETH: 'assets/images/Ethereum_3d.png',
  BCH: 'assets/images/BitcoinCash_3d.png',
  ADA: 'assets/images/Cordana_3d.png',
  XRP: 'assets/images/Ripple_3d.png',
  XLM: 'assets/images/Stellar_3d.png',
  USDT: 'assets/images/Tether_3d.png',
  SHIB: 'assets/images/shib.png',
};

export class Currency {
  id: string;
  name: string;
  iconPath: string;
  usdcPrice: number;
  irrBuyPrice: number;
  irrSellPrice: number;
  priceDiff: number;
  date: string;
  hasTag: boolean;
  iconPath3d: string;

  constructor(input: any = {}) {
    this.id = input.id ?? input?.coin;
    this.name = input.name;
    this.iconPath = input.icon_path;
    this.usdcPrice = +(input.usdc_price ?? input.USDC) ?? undefined;
    this.irrBuyPrice = (input.irr_buy_price > 10) ? Math.round(input.irr_buy_price / 10) : input.irr_buy_price / 10;
    this.irrSellPrice = (input.irr_sell_price > 10) ? Math.round(input.irr_sell_price / 10) : input.irr_sell_price / 10;
    this.hasTag = input.has_tag;
    this.date = input.date;
    this.iconPath3d = icons3D[this.id ?? 'BTC'];
    this.priceDiff = input.price_diff;
  }
}
