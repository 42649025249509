import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './components/profile/profile.component';
import {CoreModule} from 'src/app/core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from 'src/app/shared/shared.module';
import {NgSelectModule} from '@ng-select/ng-select';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import { AlertComponent } from './components/alert/alert.component';


@NgModule({
  declarations: [ProfileComponent, NotificationModalComponent, AlertComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    NgxSkeletonLoaderModule,
  ]
})
export class ProfileModule { }
