import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Category} from '../models/faq.model';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http: HttpService) {
  }

  getFaq(): Observable<Category[]> {
    return this.http.request('get', '/v1/user/faq').pipe(
      map((response: any) => response.data),
      map(data => data.map(faq => new Category(faq))));
  }
}
