<ng-container *ngIf="orderDetail">
  <div class="dialog-wrap" *ngIf="step === 'step1'">
    <div class="icon">
      <img class="backdrop" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath"
           alt="icon-backdrop">
      <img class="background" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath" alt="icon">
    </div>
    <div class="mt-5">
      <div class="text-white mb-30 fs-24 text-center font-weight-heavy">
        {{type === 'buy' ? 'پیش فاکتور خرید' : 'پیش فاکتور فروش'}} {{orderDetail?.currency?.name}}
      </div>

      <div class="row">
        <div class="col-md-12 mb-15">
          <div class="row align-items-center">
            <div class="col-md-5">
              <div class="value-preview text-white p-15 br-15 font-weight-bold text-center fs-18">
                {{orderDetail?.order?.amount | number:'1.0-6' | formatNumber:'toPersian'}} واحد
                از {{orderDetail?.currency?.name}}
              </div>
            </div>
            <div class="col-md-2">
              <div class="font-weight-heavy fs-20">
                معادل
              </div>
            </div>
            <div class="col-md-5">
              <div class="value-preview text-white p-15 br-15 text-center font-weight-bold fs-18">
                {{orderDetail?.order?.price * orderDetail?.order?.amount | number:'1.0-0' | formatNumber:'toPersian'}}
                تومان
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="type === 'buy'" class="col-md-12">
          <div class="value-preview flex-column p-15  br-15 ">
            <div class="d-flex justify-content-between  w-100">
              <span class="fs-18 font-weight-bold">کارمزد شبکه</span>

              <div class="fs-18 font-weight-bold">
                {{orderDetail?.network?.fee * orderDetail?.order?.price | number:'1.0-0' | formatNumber:'toPersian'}}
                تومان
              </div>
            </div>

            <div class="d-flex justify-content-between  w-100">
              <span class="fs-18 font-weight-bold">کارمزد درگاه بانکی</span>

              <div class="fs-18 font-weight-bold">
                {{orderDetail?.bankFee| number:'1.0-0' | formatNumber:'toPersian'}} تومان
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="orderDetail?.networks?.length > 0" class="col-md-12 text-end">
          <label class="fs-20 font-weight-heavy mb-10 mt-15">شبکه ارز خود را انتخاب کنید:</label>
          <div class="d-flex flex-wrap">
            <div *ngFor="let network of orderDetail?.networks" (click)="orderDetail.network = network"
                 [ngClass]="network.network === orderDetail.network.network ? 'active-network' : 'each-network'"
                 class="font-weight-bold fs-18 ms-3 mb-15 br-15 text-center position-relative text-white cursor-pointer">
              <div class="state-icon" *ngIf="network.network === orderDetail.network.network">
                <svg id="Iconly_Bulk_Discount" data-name="Iconly/Bulk/Discount" xmlns="http://www.w3.org/2000/svg"
                     width="17.499" height="17.499" viewBox="0 0 17.499 17.499">
                  <path id="Fill_1" data-name="Fill 1"
                        d="M8.749,17.5a2.629,2.629,0,0,1-1.858-.766l-.639-.639a1.316,1.316,0,0,0-.931-.384h-.9a2.638,2.638,0,0,1-2.635-2.635v-.9a1.32,1.32,0,0,0-.385-.932l-.628-.627a2.638,2.638,0,0,1-.01-3.725l.639-.64a1.316,1.316,0,0,0,.384-.931v-.9A2.638,2.638,0,0,1,4.422,1.788h.9A1.308,1.308,0,0,0,6.253,1.4L6.882.775a2.637,2.637,0,0,1,3.725-.01l.639.639a1.31,1.31,0,0,0,.93.384h.9A2.638,2.638,0,0,1,15.71,4.424v.9a1.32,1.32,0,0,0,.385.932l.628.628a2.633,2.633,0,0,1,.017,3.716c-.009.009-.017.018-.025.026l-.621.621a1.316,1.316,0,0,0-.384.931v.9a2.638,2.638,0,0,1-2.635,2.635h-.9a1.317,1.317,0,0,0-.931.385l-.629.628a2.624,2.624,0,0,1-1.866.775"
                        fill="#45454c"/>
                  <path id="Fill_4" data-name="Fill 4"
                        d="M3.249,6.5a.872.872,0,0,1-.619-.256L.257,3.868A.875.875,0,0,1,1.493,2.629L3.249,4.385,7.377.257A.875.875,0,0,1,8.613,1.495L3.868,6.24a.872.872,0,0,1-.619.256"
                        transform="translate(4.815 5.346)" fill="#845ea5"/>
                </svg>
              </div>
              {{network.network}}
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="value-preview flex-column br-15 p-15 ">
            <div class="d-flex justify-content-between  w-100">
              <span class="fs-18 font-weight-bold">جمع کل</span>

              <div class="fs-18 font-weight-bold">
                {{(orderDetail?.network?.fee * orderDetail?.order?.price) + (orderDetail?.order?.amount * orderDetail?.order?.price) + (orderDetail?.bankFee) |  number:'1.0-0' | formatNumber:'toPersian' }}
                تومان
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <p *ngIf="type === 'buy'" class="note fs-14 font-weight-light text-end mt-15">
            {{orderDetail?.network?.buyNote}}
          </p>
          <p *ngIf="type === 'sell'" class="note fs-14 font-weight-light text-end mt-15">
            مقدار {{(orderDetail?.network?.fee * orderDetail?.order?.price) + (orderDetail?.order.amount * orderDetail?.order?.price) + (orderDetail.bankFee) |  number:'1.0-0' | formatNumber:'toPersian' }}
            تومان تقریبی است، لازم به ذکر است که قیمت نهایی برای محاسبه سفارش فروش شما، لحظه ای است که تراکنش شما بیش
            از {{orderDetail?.network?.minConfirmations | formatNumber:'toPersian'}} تایید در
            شبکه {{orderDetail?.currency?.name}} بگیرد.
          </p>
        </div>

        <div class="col-md-12">
          <div class="value-preview p-15 br-15">
            <div class="d-flex justify-content-between w-100" [class.flex-column]="type==='sell'">
              <ng-container *ngIf="type === 'buy';else sellSendTimeElement">
                <div class="fs-20 font-weight-heavy">زمان ارسال ارز</div>
                <div class="fs-18 font-weight-bold">{{orderDetail?.network?.buyTime}}</div>
              </ng-container>
              <ng-template #sellSendTimeElement>
                <div class="fs-20 font-weight-heavy mb-4">زمان تسویه حساب</div>
                <div class="d-flex justify-content-between"
                     [innerHTML]="orderDetail?.network?.sellTime | safeHtml"></div>
              </ng-template>
            </div>
          </div>
        </div>
        <div *ngIf="type === 'sell'" class="col-md-12 mt-4 text-end">
          <div class="form-check">
            <input class="form-check-input" [(ngModel)]="acceptSendTime" type="checkbox" value="" id="acceptSendTime">
            <label class="form-check-label mr-30 fs-18 font-weight-bold" for="acceptSendTime">
              زمان تسویه فوق مورد تایید است
            </label>
          </div>
        </div>

        <div class="col-md-12">
          <div class="row action-items">
            <button class="btn btn-primary continue" [disabled]="!acceptSendTime && type==='sell'"
                    (click)="changeStep(type=== 'buy' ? 'step2' : 'step3')">ادامه
            </button>
            <button (click)="orderModal?.hide()" class="btn btn-outline- edit">ویرایش</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-wrap" *ngIf="step === 'step2'">
    <div class="icon">
      <img class="backdrop" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath"
           alt="icon-backdrop">
      <img class="background" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath" alt="icon">
    </div>
    <div class="text-white mb-30 fs-24 mt-5 text-center font-weight-heavy">
      خرید {{orderDetail?.currency?.name}}
    </div>


    <div class="des-box p-15 br-15 ">
      <div class="font-weight-heavy text-end mb-20 fs-20">
        قوانین خرید از زرارز
      </div>

      <ul class="rule fs-18 font-weight-bold text-end">
        <li class="mb-15">
          ۱. باتوجه به نوسانات لحظه ای در ارزهای دیجیتال، تعیین قیمت نهایی برای خرید در زرارز، زمانی است که روی درگاه
          بانکی وجه ریالی پرداخت میکنید نه زمانی که درخواست خرید ثبت میکنید.
        </li>

        <li class="mb-15">
          ۲. در وارد کردن آدرس کیف پول نهایت دقت را
          داشته باشید، درصورت بروز هرگونه خطا در ثبت آدرس کیف پول، مسئولیت آن بعهده کاربر میباشد.
        </li>
      </ul>
    </div>

    <div class="col-md-12 text-end acceptedTime">
      <div class="form-check">
        <input [(ngModel)]="policyAccepted" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
        <label class="form-check-label mr-30 fs-18 font-weight-bold" for="flexCheckDefault">
          قوانین فوق را خوانده ام و میپذیرم
        </label>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row action-items">
        <button class="btn btn-primary continue" [disabled]="!policyAccepted" (click)="changeStep('step3')">ادامه
        </button>
        <button class="btn btn-outline- edit" (click)="changeStep('step1')">بازگشت</button>
      </div>
    </div>
  </div>
  <div class="dialog-wrap" *ngIf="step === 'step3'">
    <div class="icon">
      <img class="backdrop" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath"
           alt="icon-backdrop">
      <img class="background" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath" alt="icon">
    </div>
    <div class="text-white mb-30 fs-24 mt-5 text-center font-weight-heavy">
      خرید {{orderDetail?.currency?.name}}
    </div>

    <div class="bank-des text-end mb-20">
      <span *ngIf="type === 'buy'" class="fs-20 font-weight-heavy d-block">انتخاب کارت بانکی</span>
      <span *ngIf="type === 'sell'" class="fs-20 font-weight-heavy d-block">{{user.firstName + ' ' + user.lastName}}
        عزیز،</span>
      <span *ngIf="type === 'sell'" class="fs-17 font-weight-bold d-block">شماره شبا بانکی خود را جهت تسویه حساب انتخاب نمایید.</span>
      <ng-container *ngIf="type === 'buy'">
        <span *ngIf="creditCards.length > 0;else createCreditCardElement" class="fs-18 font-weight-bold d-block">لطفا کارت بانکی مورد نظر خود را انتخاب کنید</span>
        <ng-template #createCreditCardElement>
          <span class="fs-18 font-weight-bold d-block">برای خرید ارز باید یک کارت بانکی به نام خود را ثبت نمایید.</span>
          <div class="d-flex mt-30 justify-content-center">
            <button (click)="goToProfile()" class="btn btn-primary">ایجاد کارت بانکی</button>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="type === 'sell'">
        <span *ngIf="ibanList.length > 0;else createIbanElement" class="fs-18 font-weight-bold d-block">از آنجایی که تسویه حساب با حساب های بانک آینده به صورت آنی است، پیشنهاد زرارز به شما استفاده
از حساب بانک آینده جهت تسویه حساب می باشد.</span>
        <ng-template #createIbanElement>
          <span
            class="fs-18 font-weight-bold d-block">برای فروش ارز باید یک شماره شبا یک حساب به نام خود را ثبت نمایید.</span>
          <div class="d-flex mt-30 justify-content-center">
            <button (click)="goToProfile()" class="btn btn-primary">ایجاد شماره شبا</button>
          </div>
        </ng-template>
      </ng-container>
    </div>

    <ng-container *ngIf="type === 'buy'">
      <div *ngFor="let card of creditCards"
           (click)="buySetOrderDetailCard(card.id)"
           class="active-bank br-15 p-15 mb-10 w-100 cursor-pointer">
        <div class="form-check w-100">
          <input [checked]="orderDetail.cardId === card.id" class="form-check-input" type="checkbox" value=""
                 [id]="'credit_card' + card.id">
          <label class="cursor-pointer form-check-label mr-30 fs-18 font-weight-bold d-flex justify-content-between">
            <span class="ms-2">{{card.bankName}}</span> <span>{{card.card | formatNumber:'toPersian'}}</span>
          </label>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'sell'">
      <div *ngFor="let iban of ibanList"
           (click)="sellSetOrderDetailIban(iban.id)"
           class="active-bank br-15 p-15 mb-10 w-100 cursor-pointer">
        <div class="form-check w-100">
          <input [checked]="orderDetail.ibanId === iban.id" class="form-check-input" type="checkbox"
                 [id]="'iban' + iban.id">
          <label class="cursor-pointer form-check-label mr-30 fs-18 font-weight-bold d-flex justify-content-between"
          >
            <span class="ms-2">{{iban.bankName}}</span> <span>{{iban.iban | formatNumber:'toPersian'}}</span>
          </label>
        </div>
      </div>
    </ng-container>
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="row action-items">
          <button class="btn btn-primary continue d-flex align-items-center justify-content-center"
                  [disabled]="(!orderDetail?.cardId && type=='buy') || (!orderDetail?.ibanId && type=='sell') || loading"
                  (click)="type === 'sell' ? addIban() :addCreditCard()">
            <i *ngIf="loading" class="spinner d-flex justify-content-center align-items-center ms-2">
              <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                      d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
              </svg>
            </i>
            ادامه
          </button>
          <button class="btn btn-outline- edit" (click)="changeStep(type=== 'buy' ? 'step2' : 'step1')">بازگشت</button>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-wrap" *ngIf="step === 'step4' && type==='buy'">
    <div class="icon">
      <img class="backdrop" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath"
           alt="icon-backdrop">
      <img class="background" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath" alt="icon">
    </div>
    <div class="text-white mb-30 fs-24 mt-5 text-center font-weight-heavy">
      خرید {{orderDetail?.currency?.name}}
    </div>


    <div class="mb-20 text-end">
      <span class="fs-20 font-weight-heavy d-block">انتخاب کیف پول</span>
      <span *ngIf="(wallets | currencyWallet:orderDetail?.currency?.id).length > 0;else createWalletElement"
            class="fs-18 font-weight-bold d-block">از لیست کیف پول های خود یک گزینه را انتخاب کنید</span>
      <ng-template #createWalletElement>
        <span class="fs-18 font-weight-bold d-block">برای خرید ارز باید حداقل یک کیف پول برای ارز خریداری شده داشته باشید.</span>
        <div class="d-flex mt-30 justify-content-center">
          <button (click)="goToProfile()" class="btn btn-primary">ایجاد کیف پول</button>
        </div>
      </ng-template>
    </div>

    <div *ngFor="let wallet of wallets | currencyWallet:orderDetail?.currency?.id"
         class="active-wallet flex-column align-items-start w-100 mb-10 p-15 br-15 cursor-pointer"
         (click)="setOrderDetailWallet(wallet)">
      <div class="form-check ">
        <input [checked]="orderDetail.wallet === wallet" class="form-check-input" type="checkbox" value=""
               [id]="'wallet' + wallet.id">
        <label class="form-check-label text-nowrap mr-30 fs-18 cursor-pointer" [attr.for]="'wallet' + wallet.id">
          <img [src]="wallet.currency?.iconPath" height="24" width="24" alt="currency_icon" class="ml-10"/>
          {{wallet.currency?.name}} ({{wallet.currency?.id}})
        </label>
      </div>

      <div class="fs-18 font-weight-bold w-address w-100 py-1">
        {{wallet.wallet}}
      </div>
    </div>


    <div class="wallet-note fs-14 font-weight-light text-end">
    <span class="d-block">
      برای خرید ارز شما فقط مجاز به ثبت آدرس کیف پول خودتان هستید.
    </span>
      <span class="d-block">
           زرارز هیچگونه مسیولتی در قبال سایر آدرس کیف پول ها نظیر درگاه های پرداخت، فروشگاه های آنلاین و … برعهده نمیگیرد.
    </span>
    </div>


    <div class="col-md-12">
      <div class="row action-items">
        <button class="btn btn-primary continue d-flex align-items-center justify-content-center"
                [disabled]="!orderDetail?.wallet?.id || loading" (click)="addWallet()">
          <i *ngIf="loading" class="spinner d-flex justify-content-center align-items-center ms-2">
            <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
            </svg>
          </i>
          ادامه
        </button>
        <button class="btn btn-outline- edit" (click)="changeStep('step3')">بازگشت</button>
      </div>
    </div>

  </div>
  <div class="dialog-wrap" *ngIf="step === 'step4' && type === 'sell'">
    <div class="icon">
      <img class="backdrop" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath"
           alt="icon-backdrop">
      <img class="background" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath" alt="icon">
    </div>
    <div class="mb-4 mt-20 mt-5 text-end">
    <span class="fs-20 font-weight-heavy d-block">
      ارسال ارز به کیف پول زرارز
    </span>
      <span class="fs-18 font-weight-bold d-block">
مقدار {{orderDetail?.currency?.name}} خود را به آدرس زیر ارسال نمایید.     </span>
      <span class="fs-18 font-weight-bold d-block">
فقط ارسال {{orderDetail?.currency?.name}} ({{orderDetail?.currency?.id}}) به آدرس زیر معتبر می باشد، ارسال هر گونه ارز دیگری به این آدرس معتبر نمی باشد.    </span>
    </div>


    <div class="col-md-12">
      <div class="row justify-content-center align-items-center">
        <div class="QR-code-preview">
          <img width="145" height="145"
               src="https://chart.apis.google.com/chart?cht=qr&chs=150x150&chld=L|0&chl={{sellWalletAddress}}"
               alt="qr_code">
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="d-flex">
        <div class="copy-icon cursor-pointer" (click)="copied()" id="copy_clipboard_address"
             [attr.data-clipboard-text]="sellWalletAddress">
          <svg xmlns="http://www.w3.org/2000/svg" width="36.5" height="36.5" viewBox="0 0 36.5 36.5">
            <g transform="translate(-1.75 -1.75)">
              <path
                d="M16.9,13.5H32.2a3.4,3.4,0,0,1,3.4,3.4V32.2a3.4,3.4,0,0,1-3.4,3.4H16.9a3.4,3.4,0,0,1-3.4-3.4V16.9A3.4,3.4,0,0,1,16.9,13.5Z"
                transform="translate(1.4 1.4)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2.5"/>
              <path d="M8.1,25.1H6.4A3.4,3.4,0,0,1,3,21.7V6.4A3.4,3.4,0,0,1,6.4,3H21.7a3.4,3.4,0,0,1,3.4,3.4V8.1"
                    transform="translate(0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2.5"/>
            </g>
          </svg>
        </div>
        <div class="copy-text fs-18 font-weight-bold p-20 br-15 d-flex justify-content-between align-items-center"
             [class.ms-3]="sellMemoTag">
          آدرس بیت کوین زرارز
          <span>
          {{sellWalletAddress}}
        </span>
        </div>
        <div *ngIf="sellMemoTag"
             class="copy-text fs-18 font-weight-bold p-20 br-15 d-flex justify-content-between align-items-center">
          آدرس تگ یا ممو
          <span>
          {{sellMemoTag}}
        </span>
        </div>

      </div>
    </div>
    <div class="col-md-12">
      <div class="row action-items">
        <button class="btn btn-primary continue d-flex align-items-center justify-content-center" [disabled]="loading"
                (click)="submitSellRequest()">
          <i *ngIf="loading" class="spinner d-flex justify-content-center align-items-center ms-2">
            <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
            </svg>
          </i>
          ثبت نهایی
        </button>
        <button class="btn btn-outline- edit" (click)="changeStep('step3')">مرحله قبل</button>
      </div>
    </div>
  </div>

  <div class="dialog-wrap" *ngIf="step === 'step5'">
    <div class="icon">
      <img class="backdrop" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath"
           alt="icon-backdrop">
      <img class="background" [src]="orderDetail?.currency?.iconPath3d || orderDetail?.currency?.iconPath" alt="icon">
    </div>
    <div class="text-white mb-30 fs-24 mt-5 text-center font-weight-heavy">
      خرید {{orderDetail?.currency?.name}}
    </div>

    <div class="mb-20 text-end">
      <span class="fs-20 font-weight-heavy d-block">تایید نهایی و پرداخت</span>
      <span class="fs-18 font-weight-bold d-block">لطفا تمام بخش ها را بررسی کنید و سپس اقدام به پرداخت کنید</span>
    </div>


    <div class="payment-preview p-15 br-15 mb-36">
      <ul>
        <li>
          <span class="fs-18 font-weight-bold">مقدار تقریبی ارز دریافتی:</span>
          <span class="fs-18 font-weight-bold">{{orderDetail?.order?.amount}}
            واحد از {{orderDetail?.currency?.name}}</span>
        </li>

        <li>
          <span class="fs-18 font-weight-bold">نوع ارز:</span>
          <span class="fs-18 font-weight-bold">{{orderDetail?.currency?.name}}</span>
        </li>

        <li>
          <div class="fs-18 font-weight-bold text-nowrap">آدرس کیف پول:</div>
          <div class="fs-18 font-weight-bold overflow-auto">{{orderDetail?.wallet?.wallet}}</div>
          <!-- <span class="fs-18 font-weight-bold text-nowrap">آدرس کیف پول:</span>
          <span class="fs-18 font-weight-bold overflow-ellipsis">{{orderDetail?.wallet?.wallet}}</span> -->
        </li>

        <li>
          <span class="fs-18 font-weight-bold">قیمت جهانی ارز :</span>
          <span class="fs-18 font-weight-bold">{{orderDetail?.order?.price | number:'1.0-0' | formatNumber:'toPersian'}}
            تتر</span>
        </li>

        <li>
          <span class="fs-18 font-weight-bold">پرداختی شما:</span>
          <span
            class="fs-18 font-weight-bold">{{(orderDetail?.order?.price * orderDetail?.order?.amount) | number:'1.0-0' | formatNumber:'toPersian'}}
            تومان</span>
        </li>

        <li>
          <span class="fs-18 font-weight-bold">کارمزد درگاه بانکی</span>
          <span class="fs-18 font-weight-bold">{{orderDetail?.bankFee | number:'1.0-0' | formatNumber:'toPersian'}}
            تومان</span>
        </li>

        <li>
          <span class="fs-18 font-weight-bold">کارمزد شبکه:</span>
          <span
            class="fs-18 font-weight-bold">  {{orderDetail?.network?.fee * orderDetail?.order?.price | number:'1.0-0' | formatNumber:'toPersian'}}
            تومان</span>
        </li>

        <li class="total-txt">
          <span class="fs-20 font-weight-heavy">جمع کل پرداختی شما:</span>
          <span
            class="fs-20 font-weight-heavy">{{(orderDetail?.network?.fee * orderDetail?.order?.price) + (orderDetail?.order?.amount * orderDetail?.order?.price) + (orderDetail?.bankFee) |  number:'1.0-0' | formatNumber:'toPersian' }}
            تومان</span>
        </li>

        <li>
          <span class="fs-18 font-weight-bold">زمان واریز ارز:</span>
          <span class="fs-18 font-weight-bold">{{orderDetail?.network?.buyTime}}</span>
        </li>
      </ul>
    </div>


    <div class="col-md-12">
      <div class="row action-items">
        <button class="btn btn-primary continue d-flex align-items-center justify-content-center" [disabled]="loading"
                (click)="getPaymentLink()">
          <i *ngIf="loading" class="spinner d-flex justify-content-center align-items-center ms-2">
            <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
            </svg>
          </i>
          پرداخت و ثبت نهایی
        </button>
        <button class="btn btn-outline- edit" (click)="changeStep('step4')">بازگشت</button>
      </div>
    </div>

  </div>

</ng-container>



