import {Injectable} from '@angular/core';
import {HttpService} from 'src/app/shared/services/http.service';
import {Observable} from 'rxjs';
import {Alert} from 'src/app/shared/models/alert.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private http: HttpService) {
  }

  createAlert(alert: Alert): Observable<any> {
    return this.http.request('post', '/v1/user/price-alert', alert);
  }

  getAlerts(): Observable<Alert[]> {
    return this.http.request('get', '/v1/user/price-alert').pipe(
      map((response: any) => response.data),
      map((alerts: any[]) => alerts.map(alert => new Alert(alert)))
    );
  }

  deleteAlert(alert: Alert): Observable<any> {
    return this.http.request('delete', `/v1/user/price-alert/${alert.id}`);
  }
}
