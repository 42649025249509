import {Directive, ElementRef, EventEmitter, HostListener, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[appLimitDecimalNumber]'
})
export class LimitDecimalNumberDirective implements OnInit {

  private el: HTMLInputElement;


  constructor(private elementRef: ElementRef, private control: NgControl) {
    this.el = this.elementRef.nativeElement;
  }

  convertToEnglishNumber(value: string): string {
    const persianNumbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰'];
    const arabicNumbers = ['١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠'];
    const englishNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    return value.split('').map(c => englishNumbers[persianNumbers.indexOf(c)] ||
      englishNumbers[arabicNumbers.indexOf(c)] || c).join('');
  }

  countDecimal(value: number): number {
    if (Math.floor(value.valueOf()) === value.valueOf()) {
      return 0;
    }
    if (value.toString().split('.').length === 1) {
      return 0;
    }
    return value.toString().split('.')[1].length || 0;
  }

  addCommas(value: any): string {
    let nStr = value.toString();
    nStr += '';
    const x = nStr.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  }

  @HostListener('ngModelChange', ['$event'])
  onchange(event: any): void {
    const value = this.el.value;
    const precision = 8;

    let val: any = this.convertToEnglishNumber(value.replace(/\,/g, ''));

    const splittedVar = val.split('.');
    let end = '';
    if (splittedVar.hasOwnProperty(1)) {
      end = '.' + splittedVar[1].slice(0, precision);
    } else {
      end = '';
    }
    if (val !== '') {
      val = Number(val);
    }

    if (isNaN(val) || !Number.isSafeInteger(parseInt(val, 10))) {
      return;
    }
    if (this.countDecimal(val) > precision) {
      this.el.value = this.addCommas(Number(val.toString().slice(0, precision - this.countDecimal(val))));
      this.control.valueAccessor?.writeValue(Number(val.toString().slice(0, precision - this.countDecimal(val))));
    } else {
      this.el.value = this.addCommas(parseInt(val, 10)) + end;
    }

  }

  overrideNgModeOnChange(value: any): number {
    let val: any = this.convertToEnglishNumber(value.replace(/\,/g, ''));
    if (val !== '') {
      val = Number(val);
    }

    if (isNaN(val) || !Number.isSafeInteger(parseInt(val, 10))) {
      return 0;
    }
    return val;
  }

  @HostListener('keydown', ['$event'])
  onKeydown(e: any): void {

    // Allow: backspace, delete, tab, escape, enter and .
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A,Ctrl+C,Ctrl+X,Ctrl+V, Command+A
      ((e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 88) && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }

  }

  ngOnInit(): void {
    const initialOnChange = (this.control.valueAccessor as any).onChange;

    (this.control.valueAccessor as any).onChange = (value: any) => initialOnChange(this.overrideNgModeOnChange(value));
  }

}
