import {Currency} from 'src/app/shared/models/currency.model';

export class Wallet {
    id: number;
    name: string;
    wallet: string;
    currencyId: string;
    currency: Currency;
    tag: string;

    constructor(input: any = {}) {
        this.id = input.id;
        this.name = input.name;
        this.wallet = input.wallet;
        this.currencyId = input.currency_id;
        this.currency = new Currency(input.currency);
        this.tag = input.tag;
    }
}
