<ng-container *ngIf="history else skeleton">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let coin of history;let i=index">
      <ng-template carouselSlide [id]="i.toString()">

        <div class="chart">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center coin-info">
              <img class="coin-icon" [src]="coin?.current?.iconPath" alt="btc">
              <div class="coin-name-chart">
                <div class="d-flex name" style="white-space: nowrap">
                  <span>{{coin?.current?.name}}</span>
                  <span class="coin-icon">({{coin?.current?.id}})</span>
                </div>
                <p *ngIf="coin?.current?.priceDiff >= 0" class="m-0 price-up">
                  +{{coin?.current?.priceDiff | formatMoney | formatNumber:'toPersian'}}%</p>
                <p *ngIf="coin?.current?.priceDiff < 0" class="m-0 price-down">
                  {{coin?.current?.priceDiff | formatMoney | formatNumber:'toPersian' }}%</p>
              </div>
            </div>
          </div>
          <div>
            <canvas baseChart width="225" height="106"
                    [datasets]="coin.chartData"
                    [labels]="coin.chartLabel"
                    [options]="lineChartOptions"
                    [colors]="coin.lineChartColors"
                    [chartType]="lineChartType">
            </canvas>
          </div>
        </div>
        <div class="price-holder">
          <p
            class="price">{{(coin?.current?.irrBuyPrice) | formatMoney | formatNumber:'toPersian'}}</p>
          <p class="currency">تومان</p>
        </div>
        <div class="price-holder">
          <p class="price">{{coin?.current?.usdcPrice | formatMoney | formatNumber:'toPersian'}}</p>
          <p class="currency">تتر</p>
        </div>
      </ng-template>
    </ng-container>
    <ng-template carouselSlide [id]="'last'">
      <div routerLink="/live-price" class="show-all-card">
        <img src="assets/images/show-all.png">
        <div class="show-all-text">
          <p class="font-weight-heavy">نمایش همه</p>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</ng-container>
<ng-template #skeleton>
  <div class="d-flex">
    <div class="skeleton-container">
      <div class="chart">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center coin-info">
            <div>
              <ngx-skeleton-loader class="coin-icon" appearance="circle" animation="progress-dark"></ngx-skeleton-loader>
            </div>
            <div class="d-flex flex-column position-relative" style="top: 3px">
              <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
              <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <div>
          <ngx-skeleton-loader class="chart-skeleton" animation="progress-dark"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="price-holder">
        <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
      </div>
      <div class="price-holder">
        <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="skeleton-container d-none d-md-block">
      <div class="chart">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center coin-info">
            <div>
              <ngx-skeleton-loader class="coin-icon" appearance="circle" animation="progress-dark"></ngx-skeleton-loader>
            </div>
            <div class="d-flex flex-column position-relative" style="top: 3px">
              <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
              <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <div>
          <ngx-skeleton-loader class="chart-skeleton" animation="progress-dark"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="price-holder">
        <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
      </div>
      <div class="price-holder">
        <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="skeleton-container d-none d-md-block">
      <div class="chart">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center coin-info">
            <div>
              <ngx-skeleton-loader class="coin-icon" appearance="circle" animation="progress-dark"></ngx-skeleton-loader>
            </div>
            <div class="d-flex flex-column position-relative" style="top: 3px">
              <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
              <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <div>
          <ngx-skeleton-loader class="chart-skeleton" animation="progress-dark"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="price-holder">
        <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
      </div>
      <div class="price-holder">
        <ngx-skeleton-loader class="coin-title" animation="progress-dark"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>
