<div class="modal fade" id="notification-modal" tabindex="-1" role="dialog" aria-labelledby="notification-modal"
     aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content card card-gradient">
      <div class="modal-body">
        <div class="d-flex justify-content-center align-items-center position-relative mb-4">
          <p class="mb-0 font-weight-heavy fs-24">سرویس اطلاع رسانی</p>
          <a data-bs-dismiss="modal" id="dismissModal" class="close-icon">
            <svg aria-label="Close"
                 data-name="Iconly/Bulk/Close Square"
                 xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
              <path id="Fill_1" data-name="Fill 1"
                    d="M23.9,0H9.45C3.8,0,0,3.967,0,9.867V23.483c0,5.883,3.8,9.85,9.45,9.85H23.9c5.65,0,9.433-3.967,9.433-9.85V9.867C33.333,3.967,29.55,0,23.9,0"
                    transform="translate(3.333 3.333)" fill="#fff" opacity="0.4"/>
              <path id="Fill_4" data-name="Fill 4"
                    d="M10.483,8.42,7.518,5.456l2.963-2.963A1.458,1.458,0,0,0,8.42.43L5.455,3.393,2.49.426A1.459,1.459,0,0,0,.426,2.49L3.393,5.456.433,8.415a1.458,1.458,0,0,0,2.062,2.063l2.96-2.96,2.967,2.965A1.458,1.458,0,0,0,10.483,8.42"
                    transform="translate(14.543 14.531)" fill="#fff"/>
            </svg>
          </a>
        </div>
        <div class="title">
          <div class="d-flex justify-content-center mb-3">
            <p class="mb-0 text-center">
              جهت اطلاع از نوسان قیمت خرید یا فروش ارز دلخواه خود، فرم زیر را تکمیل نمایید تا
              زرارز
              از طریق پیامک شما را در جریان تغییرات قرار دهد.
            </p>
          </div>
          <form (ngSubmit)="createAlert()">
            <div class="row mb-3">
              <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                <ng-select [searchable]="false" [clearable]="false" name="crypto-name" [(ngModel)]="currency">
                  <ng-option *ngFor="let coin of cryptoList" [value]="coin">
                    <div class="d-flex w-100 align-items-center">
                      <img class="coin-icon" [src]="coin.iconPath" alt="btc">
                      <span>{{coin.name}} ({{coin.id}})</span>
                    </div>
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-12 col-lg-6">
                <div class="inner-card button-group m-auto">
                  <div [class.left]="alert.sideType" [class.right]="alert.sideType == 'sell'"
                       class="btn-primary placeholder left"></div>
                  <div (click)="alert.sideType = 'sell'" class="btn">خرید</div>
                  <div (click)="alert.sideType = 'buy'" class="btn">فروش</div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                <div class="inner-card button-group m-auto">
                  <div [class.left]="alert.priceType" [class.right]="alert.priceType == 'greater_than'"
                       class="btn-primary placeholder left"></div>
                  <div (click)="alert.priceType = 'greater_than'" class="btn">بیشتر از</div>
                  <div (click)="alert.priceType = 'lower_than'" class="btn">کمتر از</div>
                </div>
              </div>
              <div class="col-12 col-lg-6 ">
                <input appLimitDecimalNumber name="trigger-price" [(ngModel)]="triggerPrice" dir="ltr" type="text"
                       placeholder="قیمت">
              </div>
            </div>
            <div class="d-flex justify-content-center mb-3">
              <button type="submit" [disabled]="loading" class="btn btn-primary">
                <ng-container *ngIf="loading == false else loadingSpinner">
                  به من اطلاع بده!
                </ng-container>
                <ng-template #loadingSpinner>
                  <i class="spinner d-flex justify-content-center align-items-center ms-2">
                    <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="currentColor"
                            d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
                    </svg>
                  </i>
                </ng-template>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
