import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {User} from 'src/app/shared/models/user.model';
import * as Cookie from 'js-cookie';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private http: HttpService, private router: Router) {
  }

  signIn(phone: string, isForgetPassword?: boolean): Observable<any> {
    if (isForgetPassword) {
      return this.http.request('post', '/v1/forget-password/send-sms', {
        phone
      });
    } else {
      return this.http.request('post', '/v1/user/initialize-login', {
        phone,
      });
    }
  }

  setNewPassword(code: string, phone: string, password: string): Observable<any> {
    return this.http.request('post', '/v1/forget-password/new-password', {
      phone,
      code,
      password
    });
  }

  signUp(data = {}): Observable<any> {
    return this.http.request('post', '/v1/user/register', data);
  }

  getTokenRequest(phone: string, password: string): Observable<any> {
    return this.http.request('post', '/v1/user/get-token', {phone, password});
  }

  setToken(token: string, rememberMe: boolean): void {
    if (rememberMe) {
      localStorage.setItem('token', token);
    } else {
      Cookie.set('token', token, {
        secure: true
      });
    }
  }

  sendCode(code: string, phone: string, isForgetPassword?: boolean): Observable<any> {
    if (isForgetPassword) {
      return this.http.request('post', '/v1/forget-password/check-code', {
        phone,
        code
      });
    } else {
      return this.http.request('post', '/v1/user/verify-phone', {code, phone});
    }
  }

  set userRole(role: string) {
    localStorage.setItem('role', role);
  }

  get userRole(): string {
    return localStorage.getItem('role') || undefined;
  }

  signOut(): void {
    localStorage.clear();
    Cookie.remove('token');
    this.router.navigateByUrl('/authentication/sign-in');
  }

  signOutWithoutRedirect(): void {
    localStorage.clear();
    Cookie.remove('token');
  }

  checkUserSignedIn(): boolean {
    return !!localStorage.getItem('token') || !!sessionStorage.getItem('token') || false;
  }

  getToken(): string {
    return localStorage.getItem('token') || Cookie.get('token') || undefined;
  }

  usernameAvailability(username: string): Observable<any> {
    return this.http.request('post', '/v1/page/check-availability', {
      username
    });
  }

  setUserInfo(data: any): void {
    localStorage.setItem('userInfo', JSON.stringify(data));
  }

  getUserInfo(): User {
    return new User(JSON.parse(localStorage.getItem('userInfo') ?? '{}'));
  }
}
