import {Utility} from 'src/app/core/interceptor/utility';
import {Avatar} from 'src/app/shared/models/avatar.model';

export class User {
  firstName: string;
  lastName: string;
  fatherName: string;
  referencePhone: string;
  birthday: string;
  phone: string;
  password: string;
  authenticated: 'UNKNOWN' | 'ACCEPTED' | 'PENDING' | 'REJECTED' = 'UNKNOWN';
  createdAt: string;
  thumbnailProfile: string;
  avatar: Avatar;

  constructor(input: any = {}) {
    Object.assign(this, Utility.camelize(input ?? {}));
  }
}
