import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Currency} from 'src/app/shared/models/currency.model';
import {CoinService} from 'src/app/shared/services/coin-service';
import {SubSink} from 'subsink';
import {UserService} from 'src/app/shared/services/user.service';
import {User} from 'src/app/shared/models/user.model';
import {DateService} from 'src/app/shared/services/date.service';
import {DateModel} from 'src/app/shared/models/date.model';
import {Card} from 'src/app/shared/models/card.model';
import {HttpError} from 'src/app/shared/models/http-error.model';
import {Iban} from 'src/app/shared/models/iban.model';
import {Wallet} from 'src/app/shared/models/wallet';
import Swal from 'sweetalert2';
import {ModalService} from 'src/app/shared/services/modal.service';
import {Modal} from 'bootstrap';
import {Avatar} from 'src/app/shared/models/avatar.model';
import {FormatNumberPipe} from '../../../../../shared/components/pipes/format-number.pipe';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  fullName = '';
  birthday = {} as DateModel;
  @ViewChild('ccNumber') ccNumber: ElementRef;
  cardForm: FormGroup;
  shebaForm: FormGroup;
  dateFormat = 'jYYYY/jMM/jDD';
  cardLoading = false;
  cardLoadingData = false;
  shebaLoading = false;
  shebaLoadingData = false;
  userCard: Card = new Card();
  userCardList: Card[] = [];
  userIban: Iban = new Iban();
  userIbanList: Iban[] = [];
  saveWallet: Wallet = new Wallet();
  userWallet: Wallet[];
  isLt = true;
  error: any = {};
  coins: Currency[] = [];
  tradeCoin: Currency;
  user: User = new User();
  btc: Currency;
  shabaNumFill = false;
  private sub = new SubSink();
  Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  bankCardRegister = false;
  deleteShabaLoading = false;
  deleteCardLoading = false;
  deleteWalletLoading = false;
  deletedWalletId: number;
  saveWalletLoading = false;
  avatarModal: Modal;
  avatarList: Avatar[] = [];
  saveAvatarLoading = false;

  constructor(
    private coinService: CoinService,
    private userService: UserService,
    private dateService: DateService,
    private modalService: ModalService,
    private formatNumber: FormatNumberPipe,
  ) {
  }

  getCoinList(): void {
    this.sub.sink = this.coinService.getListOfCoins().subscribe((coins) => {
      this.coins = coins;
      if (this.tradeCoin === undefined) {
        this.tradeCoin = this.btc = this.coins.find(coin => coin.id === 'BTC');
      }
    });
  }

  getUserProfile(): void {
    this.sub.sink = this.userService.getUser().subscribe((user) => {
      this.userService.userStream.next(user);
    });
  }

  saveCard(): void {
    this.cardLoading = true;
    let cardNum = this.userCard.card.replace(/\s/g, '');
    cardNum = this.formatNumber.transform(cardNum, 'toEnglish');
    this.sub.sink = this.userService.saveCard(cardNum).subscribe(() => {
      this.cardLoading = false;
      this.bankCardRegister = true;
      this.userCard = new Card();
      this.getCard();
    }, (response: HttpError) => {
      this.cardLoading = false;
      if (response.status === 422) {
        this.userCard.errors = response.validationError;
      }
      if (response.status === 400 && response.status) {
        console.log('errors', response);
        this.userCard.errors = response.error;
      }
    });
  }

  saveIban(): void {
    this.shebaLoading = true;
    const shebaNumber = JSON.parse(JSON.stringify(this.userIban));
    shebaNumber.iban = this.formatNumber.transform(shebaNumber.iban, 'toEnglish');
    shebaNumber.iban = shebaNumber.iban.replace(/^/, 'IR');
    this.sub.sink = this.userService.saveIban(shebaNumber).subscribe(() => {
      this.userIban = new Iban();
      this.shebaLoading = false;
      this.shabaNumFill = true;
      this.getIban();
    }, (response: HttpError) => {
      this.shebaLoading = false;
      if (response.status === 422) {
        this.userIban.errors = response.validationError;
      }
    });
  }

  getCard(): void {
    this.cardLoading = true;
    this.cardLoadingData = true;
    this.sub.sink = this.userService.getCard().subscribe((cards) => {
      this.cardLoading = false;
      this.cardLoadingData = false;
      if (cards.length > 0) {
        // this.userCard = cards[0];
        this.userCardList = cards;
        this.userCardList.filter(card => {
          card.bankName = this.userService.getBankName({
            type: 'card',
            value: card.card
          });
        });
        // this.userCard.card = this.userCard.card?.replace(/(.{4})/g, '$1  ').trim() ?? cards[0].card;
        this.bankCardRegister = true;
      }
    });
  }

  getIban(): void {
    this.shebaLoading = true;
    this.shebaLoadingData = true;
    this.sub.sink = this.userService.getIban().subscribe((ibans) => {
      this.shebaLoading = false;
      this.shebaLoadingData = false;
      if (ibans.length > 0) {
        // this.userIban = ibans[0];
        this.userIbanList = ibans;
        console.log('this.userIbanList', this.userIbanList);
        this.userIbanList.filter(iban => {
          iban.iban = iban.iban.replace('IR', '');
          iban.bankName = this.userService.getBankName({
            type: 'sheba',
            value: iban.iban
          });
        });

        this.shabaNumFill = true;
      }
    });
  }

  createWallet(): void {
    this.saveWalletLoading = true;
    this.saveWallet.currencyId = this.tradeCoin.id;
    this.saveWallet.currency = this.tradeCoin;
    this.sub.sink = this.userService.createWallet(this.saveWallet).subscribe((response: any) => {
      if (response.status === 'OK') {
        this.Toast.fire({icon: 'success', title: 'کیف پول با موفقیت ثبت شد.'});
        this.userWallet.push(this.saveWallet);
        this.saveWallet = new Wallet();
        this.tradeCoin = this.btc;
        this.getWallet();
        this.saveWalletLoading = false;
      }
    }, (response: HttpError) => {
      if (response.status === 422) {
        this.error = response.validationError;
      } else {
        this.Toast.fire({icon: 'error', text: response.error});
      }
      this.saveWalletLoading = false;
    });
  }

  removeWallet(wallet: Wallet): void {
    Swal.fire({
        title: 'آیا مطمئن هستید؟',
        text: 'این عملیات قابل بازگشت نیست.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'بله',
        cancelButtonText: 'انصراف',
        confirmButtonColor: '#FF5F5F',
        cancelButtonColor: '#845ea5'
    }).then((result) => {
        if (result.isConfirmed) {
            this.deleteWalletLoading = true;
            this.deletedWalletId = wallet.id;

            this.sub.sink = this.userService.removeWallet(wallet.id).subscribe((response) => {
                if (response.status === 'OK') {
                    this.userWallet.splice(this.userWallet.indexOf(wallet), 1);
                    this.Toast.fire({ icon: 'success', text: 'کیف پول با موفقیت حذف شد.' });
                    this.deleteWalletLoading = false;
                }
            }, (response) => {
                if (response.status !== 422) {
                    this.Toast.fire({ icon: 'error', text: response.error });
                }
                this.deleteWalletLoading = false;
            });
        }
    });
}

  removeCard(index: number): void {
    Swal.fire({
        title: 'آیا مطمئن هستید؟',
        text: 'این عملیات قابل بازگشت نیست.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'بله',
        cancelButtonText: 'انصراف',
        confirmButtonColor: '#FF5F5F',
        cancelButtonColor: '#845ea5'
    }).then((result) => {
        if (result.isConfirmed) {
            this.deleteCardLoading = true;
            this.sub.sink = this.userService.removeCard(this.userCardList[index].id).subscribe((response) => {
                if (response.status === 'OK') {
                    this.Toast.fire({ icon: 'success', text: 'کارت با موفقیت حذف شد.' });
                    this.userCardList.splice(index, 1);
                    this.deleteCardLoading = false;
                    this.bankCardRegister = false;
                }
            }, (response) => {
                if (response.status !== 422) {
                    this.Toast.fire({ icon: 'error', text: response.error });
                }
                this.deleteCardLoading = false;
            });
        }
    });
}

  showAvatarList(): void {
    this.avatarModal.show();
  }

  removeIban(index: number): void {
    Swal.fire({
        title: 'آیا مطمئن هستید؟',
        text: 'این عملیات قابل بازگشت نیست.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'بله',
        cancelButtonText: 'انصراف',
        confirmButtonColor: '#FF5F5F',
        cancelButtonColor: '#845ea5'
    }).then((result) => {
        if (result.isConfirmed) {
            this.deleteShabaLoading = true;
            this.sub.sink = this.userService.removeIban(this.userIbanList[index].id).subscribe((response) => {
                if (response.status === 'OK') {
                    this.Toast.fire({ icon: 'success', text: 'شماره شبا با موفقیت حذف شد.' });
                    this.userIbanList.splice(index, 1);
                    this.deleteShabaLoading = false;
                    this.shabaNumFill = false;
                }
            }, (response) => {
                if (response.status !== 422) {
                    this.Toast.fire({ icon: 'error', text: response.error });
                }
                this.deleteShabaLoading = false;
            });
        }
    });
}

  creditCardNumberSpacing(): void {
    const input = this.ccNumber.nativeElement;
    const {selectionStart} = input;
    const {creditCardNum} = this.cardForm.controls;
    let trimmedCardNum = creditCardNum.value.replace(/\s+/g, '');

    if (trimmedCardNum.length > 16) {
      trimmedCardNum = trimmedCardNum.substr(0, 16);
    }

    const partitions = [4, 4, 4, 4];

    const numbers = [];
    let position = 0;
    partitions.forEach(partition => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) {
        numbers.push(part);
      }
      position += partition;
    });

    creditCardNum.setValue(numbers.join('  '));

    if (selectionStart < creditCardNum.value.length - 2) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }
  }

  getWallet(): void {
    this.sub.sink = this.userService.getWallet().subscribe((wallets) => {
      this.userWallet = wallets?.reverse() ?? [];
    });
  }

  getAvatarList(): void {
    this.sub.sink = this.userService.getAvatarList().subscribe((avatar) => {
      this.avatarList = avatar;
    });
  }

  setNewAvatar(avatar: Avatar): void {
    this.user.avatar = avatar;
    this.avatarModal.hide();
    this.sub.sink = this.userService.saveAvatar(this.user).subscribe(() => {
    });
  }

  ngOnInit(): void {
    this.getCoinList();
    this.getAvatarList();
    this.cardForm = new FormGroup({
      creditCardNum: new FormControl('', [Validators.required,
        Validators.pattern('^[ 0-9]*$'),
        Validators.minLength(17)]),
    });

    this.shebaForm = new FormGroup({
      shebaNum: new FormControl('', [Validators.required, Validators.maxLength(24)])
    });

    this.sub.sink = this.modalService.modalStream.subscribe((modal) => {
      if (modal) {
        this.avatarModal = modal;
      }
    });


    this.getUserProfile();

    this.sub.sink = this.userService.userStream.subscribe(user => {
      if (user.firstName) {
        this.fullName = `${user.firstName}  ${user.lastName}`;
        this.user.birthday = this.dateService.convertToShamsei(user.birthday);
        this.user = user;
      }
      if (this.user.phone) {
        this.user.phone = this.user.phone.replace(/^98/g, '0');
      }
    });

    this.getCard();
    this.getIban();
    this.getWallet();
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


}
