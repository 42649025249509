<div class="row" style="padding: 19px">
  <ng-container *ngIf="isSignedIn">
    <ng-container *ngIf="orders;else loadingSkeleton">
      <div *ngFor="let order of orders" class="row-data py-3 px-4 mb-3">
        <div class="row justify-content-center">
          <div class="col col-md-3 col-xxl-2 d-flex align-items-center my-2 my-md-0">
            <img class="coin-icon" [src]="order.currency.iconPath" alt="coin">
            <p class="mb-0 fs-18" style="white-space: nowrap">{{order.currency.name}} <span class="fs-14 font-weight-light me-1">
                  ({{order.currency.id}})</span></p>
          </div>
          <div class="col col-md d-flex align-items-center justify-content-center mb-2 mb-md-0">
            <p class="mb-0 fs-18 font-weight-bold text-nowrap">{{order.order?.amount | number:'1.0-6' | formatNumber: 'toPersian'}} <span
              class="fs-14 me-1 font-weight-light">عدد</span></p>
          </div>
          <div class="col d-flex align-items-center justify-content-center mb-2 mb-md-0">
            <p
              class="mb-0 fs-14 font-weight-light text-nowrap">{{order.createdAt | dateFormat:'jYYYY/jMM/jDD' | formatNumber: 'toPersian'}}</p>
          </div>
          <div class="col d-flex align-items-center justify-content-center mb-2 mb-md-0">
            <p class="mb-0 fs-18 text-nowrap">{{order.order?.price | number:'1.0-0' | formatNumber: 'toPersian'}}<span
              class="fs-14 font-weight-light me-2">تومان</span>
            </p>
          </div>
          <div class="col-12 col-md-3 d-flex align-items-center justify-content-center mt-2 mt-md-0">
            <ng-container *ngIf="order.status !== 'FINISHED'">
              <div class="pending-status">
                <p *ngIf="order.type === 'sell'" class="mb-0 fs-12 font-weight-bold">
                  فروش درحال بررسی</p>
                <p *ngIf="order.type === 'buy'" class="mb-0 fs-12 font-weight-bold">
                  خرید درحال بررسی</p>
              </div>
            </ng-container>
            <ng-container *ngIf="order.status === 'FINISHED'">
              <div [ngClass]="order.type === 'sell' ? 'completed-sell-status': 'completed-buy-status'">
                <p *ngIf="order.type === 'sell'" class="mb-0 fs-12 font-weight-bold">
                  فروخته شده</p>
                <p *ngIf="order.type === 'buy'" class="mb-0 fs-12 font-weight-bold">
                  خریده شده</p>
              </div>
            </ng-container>
          </div>
          <div (click)="openOrder(order)" class="col-12 col-md-1 mt-2 mt-md-0 d-flex align-items-center justify-content-center cursor-pointer">
            <svg class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M14.34,0H5.67C2.28,0,0,2.38,0,5.92v8.17C0,17.62,2.28,20,5.67,20h8.67C17.73,20,20,17.62,20,14.09V5.92C20,2.38,17.73,0,14.34,0"
                transform="translate(2 2)" fill="#fff" opacity="0.4"/>
              <path
                d="M8.959,1.2a1.2,1.2,0,1,1,1.2,1.2A1.2,1.2,0,0,1,8.959,1.2Zm-4.479,0a1.2,1.2,0,1,1,1.2,1.2A1.2,1.2,0,0,1,4.479,1.2ZM0,1.2a1.2,1.2,0,1,1,1.2,1.2A1.2,1.2,0,0,1,0,1.2Z"
                transform="translate(6.323 10.804)" fill="#fff"/>
            </svg>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #loadingSkeleton>
      <div class="row-data py-3 mb-3">
        <div class="container-fluid">
          <div class="row w-100">
            <div class="col-3 col-xxl-2 d-flex align-items-center justify-content-center pe-3">
              <ngx-skeleton-loader
                class="order-loading w-100 me-3"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col-1 d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
      <div class="row-data py-3 mb-3">
        <div class="container-fluid">
          <div class="row w-100">
            <div class="col-3 col-xxl-2 d-flex align-items-center justify-content-center pe-3">
              <ngx-skeleton-loader
                class="order-loading w-100 me-3"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col-1 d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
      <div class="row-data py-3 mb-3">
        <div class="container-fluid">
          <div class="row w-100">
            <div class="col-3 col-xxl-2 d-flex align-items-center justify-content-center pe-3">
              <ngx-skeleton-loader
                class="order-loading w-100 me-3"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
            <div class="col-1 d-flex align-items-center justify-content-center">
              <ngx-skeleton-loader
                class="order-loading w-100"
                animation="progress-dark"
                count="1" appearance="line"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <div *ngIf="isSignedIn == false || orders?.length == 0 "
       class="col-12 order-1 order-lg-0 col-lg-8 d-flex align-items-center">
    <div class="p-lg-5 p-md-1">
      <p *ngIf="orders?.length == 0" class="mb-0 empty-list-title">
        متاسفانه لیست سفارشات شما خالی هست!
      </p>
      <p *ngIf="isSignedIn == false" class="m-0 empty-list-description">
        برای دیدن سفارشات خود ابتدا نیاز به ورود دارید!
      </p>
    </div>
  </div>
  <div *ngIf="isSignedIn == false || orders?.length == 0"
       class="col-12 d-flex justify-content-center  order-0 order-lg-1 col-lg-4">
    <img class="position-relative" style="bottom: 1rem" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</div>
