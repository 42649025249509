<div *ngIf="orderDetail" class="dialog-wrap">
  <div class="text-center font-weight-heavy fs-24 mb-3">
    جزئیات سفارش {{type === 'buy' ? 'خرید' : 'فروش'}}
  </div>

  <div class="d-flex justify-content-center align-items-center">
    <div class="status fs-18 font-weight-bold" [class.pending]="state<4" [class.finished]="state>=4">
      {{state >= 4 ? 'انجام شده' : 'در حال بررسی'}}
    </div>
  </div>

  <div class="col-md-12 progress-state position-relative">
    <div class="overlay"></div>
    <div class="d-flex justify-content-between">
      <div *ngIf="type=='buy'" class="form-check">
        <input class="form-check-input" type="checkbox" [checked]="state >= 1" value="" id="step1_buy">
        <label class="form-check-label" for="step1_buy">
          درخواست خرید
        </label>
      </div>
      <div *ngIf="type=='sell'" class="form-check">
        <input class="form-check-input" type="checkbox" [checked]="state >= 1" value="" id="step1_sell">
        <label class="form-check-label" for="step1_sell">
          درخواست فروش
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" [checked]="state >= 2" value="" id="step2">
        <label class="form-check-label" for="step2">
          احراز هویت
        </label>
      </div>
      <div *ngIf="type === 'buy'" class="form-check">
        <input class="form-check-input" type="checkbox" [checked]="state >= 3" value="" id="step3_buy">
        <label class="form-check-label" for="step3_buy">
          در صف ارسال ارز
        </label>
      </div>
      <div *ngIf="type === 'sell'" class="form-check">
        <input class="form-check-input" type="checkbox" [checked]="state >= 3" value="" id="step3_sell">
        <label class="form-check-label" for="step3_sell">
          در انتظار تایید شبکه
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" [checked]="state >= 4" value="" id="step4">
        <label class="form-check-label" for="step4">
          {{type === 'buy' ? 'ارز واریز شد' : 'واریز زرارز'}}
        </label>
      </div>
    </div>

    <div class="col-md-12">
      <div class="progress">
        <div class="fill" [style.width]="(state * 100) / 4 + '%'"></div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="state > 2;else userVerification">
    <div class="col-md-12">
      <div class="row align-items-center">
        <div class="col-md-5">
          <div class="value-preview font-weight-bold fs-18">
            {{orderDetail?.order?.amount | number:'1.0-6' | formatNumber:'toPersian'}} واحد
            از {{orderDetail.currency?.name}}
          </div>
        </div>
        <div class="col-md-2 d-flex justify-content-center fs-20 font-weight-heavy">
          معادل
        </div>
        <div class="col-md-5">
          <div class="value-preview font-weight-bold fs-18">
            {{(orderDetail?.order?.price * orderDetail?.order?.amount)/10 | number:'1.0-0' | formatNumber:'toPersian'}} تومان
          </div>
        </div>
      </div>

      <div class="justify-content-between">
        <div class="d-flex w-100  factor-info">
          <div class="col-md-12 d-flex justify-content-between font-weight-heavy text-primary">
            <span>شماره سفارش:</span>
            <span>{{orderDetail.id | formatNumber:'toPersian'}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="payment-preview">
        <p class="font-weight-heavy fs-20">اطلاعات سفارش</p>

        <ul>
          <li *ngIf="type === 'buy'">
            <span>پرداختی شما:</span>
            <span>{{(orderDetail?.order?.price * orderDetail?.order?.amount)/10 | number:'1.0-0' | formatNumber:'toPersian'}}
              تومان</span>
          </li>
          <li *ngIf="type === 'sell'">
            <span>مبلغ وایزی:</span>
            <span>{{(orderDetail?.order?.price * orderDetail?.order?.amount)/10 | number:'1.0-0' | formatNumber:'toPersian'}}
              تومان</span>
          </li>

          <li *ngIf="type === 'buy'">
            <span>ارز دریافتی</span>
            <span>{{orderDetail?.order?.amount | number:'1.0-6' | formatNumber:'toPersian'}}
              واحد از {{orderDetail.currency?.name}}</span>
          </li>
          <li *ngIf="type === 'sell'">
            <span>ارز ارسالی</span>
            <span>{{orderDetail?.order?.amount | number:'1.0-6' | formatNumber:'toPersian'}}
              واحد از {{orderDetail.currency?.name}}</span>
          </li>

          <li>
            <span>قیمت جهانی ارز :</span>
            <span>{{orderDetail.order?.price/10 | number:'1.0-6' | formatNumber:'toPersian'}} تومان</span>
          </li>

          <!--        <li >-->
          <!--          <span>قیمت تتر :</span>-->
          <!--          <span>۲۶،۴۰۰ تومان</span>-->
          <!--        </li>-->

          <li *ngIf="type === 'buy'">
            <span>کارمزد شبکه:</span>
            <span>{{orderDetail.network?.fee}} {{orderDetail?.currency?.name}}</span>
          </li>

          <li>
            <span>ثبت سفارش :</span>
            <span>{{orderDetail.createdAt | dateFormat:'dddd jDD jMMMM, ساعت HH:mm'}}</span>
          </li>

          <li *ngIf="type === 'buy'">
            <span>ارسال ارز:</span>
            <span
              *ngIf="state === 4;else pending">{{orderDetail.updatedAt | dateFormat:'dddd jDD jMMMM, ساعت HH:mm'}}</span>
            <ng-template #pending>
              <span>در حال انجام</span>
            </ng-template>
          </li>
          <li *ngIf="type === 'sell'">
            <span>تایید شبکه:</span>
            <span>انجام شده</span>
          </li>
          <li *ngIf="type === 'sell'">
            <span>واریز بانک:</span>
            <span>انجام شده</span>
          </li>

          <!--        <li>-->
          <!--          <span>شناسه تراکنش:(TAXID)</span>-->
          <!--          <span>42342Tg3432</span>-->
          <!--        </li>-->

          <li *ngIf="type === 'buy'">
            <span class="text-nowrap">آدرس کیف پول:</span>
            <span class="overflow-auto">{{orderDetail.wallet?.wallet}}</span>
          </li>

        </ul>

      </div>

    </div>
  </ng-container>

  <ng-template #userVerification>
    <div class="payment-preview" style="border-radius: 15px">
      <p class="font-weight-heavy text-center fs-20">احراز هویت</p>
      <p class="fs-20 mb-0 font-weight-heavy">{{user.firstName + ' ' + user.lastName}} عزیز</p>
      <ng-container *ngIf="user.authenticated === 'UNKNOWN' || user.authenticated === 'REJECTED' else authenticated">
        <ng-container *ngIf="verificationStep === 0">
          <div class="mt-3">
            <label class="d-flex mb-4">لطفا کد ملی خود را وارد کنید</label>
            <input type="text" placeholder="کد ملی خود را وارد نمایید" [class.is-invalid]="error['national_id']"
                   [(ngModel)]="nationalId">
          </div>
          <div class="d-flex">
            <div class="row action-items">
              <button class="btn btn-primary continue" [disabled]="!nationalId" (click)="next(1)">ادامه</button>
              <!--          <button (click)="orderModal?.hide()" class="btn btn-outline- edit">ویرایش</button>-->
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="verificationStep === 1">
          <div class="mt-3">
            <label class="d-flex mb-4">لطفا عکس کارت ملی خود را انتخاب کنید</label>
            <app-file [error]="error['nationalIdImage'] !== undefined" [image]="nationalIdImage"
                      (onImageSelected)="getNationalImage($event)"
                      label="تصویر کارت ملی را انتخاب کنید"></app-file>
            <app-validation-error [errors]="error" name="nationalIdImage"></app-validation-error>

          </div>
          <div class="d-flex">
            <div class="row action-items">
              <button class="btn btn-primary continue" [disabled]="!nationalIdImage" (click)="next(2)">ادامه</button>
              <!--            <button (click)="next(0)" class="btn btn-outline- edit">بازگشت</button>-->
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="verificationStep === 2">
          <div class="mt-3">
            <label class="d-flex mb-4">لطفا عکس سلفی خود را همراه با کارت ملی یا گواهینامه انتخاب کنید</label>
            <app-file [error]="error['selfie_image'] !== undefined" [image]="selfieImage"
                      (onImageSelected)="getSelfieImage($event)"
                      label="تصویر عکس سلفی را انتخاب کنید"></app-file>
            <app-validation-error [errors]="error" name="selfieImage"></app-validation-error>
          </div>
          <div class="d-flex">
            <div class="row action-items">
              <button class="btn btn-primary continue d-flex align-items-center justify-content-center"
                      [disabled]="!selfieImage || loading" (click)="saveVerification()">
                <i *ngIf="loading" class="spinner d-flex justify-content-center align-items-center ms-2">
                  <svg width="15" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img"
                       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor"
                          d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path>
                  </svg>
                </i>
                ارسال اطلاعات
              </button>
              <button (click)="next(1)" class="btn btn-outline- edit">بازگشت</button>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #authenticated>
        <div class="mt-3">
          <label class="d-flex mb-4">در حال بررسی اطلاعات هویتی شما هستیم</label>
        </div>
      </ng-template>
    </div>
  </ng-template>

</div>
