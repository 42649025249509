import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {Alert} from 'src/app/shared/models/alert.model';
import {HttpError} from 'src/app/shared/models/http-error.model';
import {AlertService} from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  alerts!: Alert[];
  deletedAlert!: Alert;
  deleteAlertLoading = false;

  constructor(private alertService: AlertService) {
  }

  getAlertList(): void {
    this.alertService.getAlerts().subscribe((alerts) => {
      this.alerts = alerts;
    });
  }

  deleteAlert(alert: Alert): void {
    this.deletedAlert = alert;
    this.deleteAlertLoading = true;
    this.alertService.deleteAlert(alert).subscribe(() => {
      this.alerts.splice(this.alerts.indexOf(alert), 1);
      this.deleteAlertLoading = false;
      this.Toast.fire({icon: 'success', text: 'اعلان با موفقیت لغو شد.'});
    }, (response: HttpError) => {
      if (response.status !== 422) {
        this.Toast.fire({icon: 'error', text: response.error});
      }
      this.deleteAlertLoading = false;
    });
  }

  ngOnInit(): void {
    this.getAlertList();
  }

}
