import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationErrorComponent implements OnInit {
  @Input() errors = {};
  @Input() name = '';
  @Input() customStyle: string[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

}
