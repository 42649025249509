<div class="box has-advanced-upload" [class.on_drag-over]="dragOver" [class.error]="error" (dragenter)="drag($event)"
     (dragover)="$event.preventDefault(); dragOver = true"
     (dragleave)="dragOver = false" (drop)="onDrop($event)" (click)="file.click()">
  <div class="box__input">

      <ng-container *ngIf="image === undefined;else imageHolder">
        <svg class="box__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43">
          <path
            d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
        </svg>
      </ng-container>
    <ng-template #imageHolder>
      <div class="box__image">
        <img  [src]="image" alt="user image">
      </div>
    </ng-template>
      <input type="file" (change)="getFile($event)" name="files" id="file" class="box__file" #file>
      <p class="label">{{label}}</p>
      <p class="text-center mb-0 mt-3">یا بکشید و بی‌اندازید</p>
  </div>
</div>
