import {Currency} from 'src/app/shared/models/currency.model';
import {Order} from 'src/app/shared/models/order.model';
import {Wallet} from 'src/app/shared/models/wallet';
import {Card} from 'src/app/shared/models/card.model';

export class OrderDetail {
  id: number;
  bankFee: number;
  createdAt: string;
  updatedAt: string;
  networks: Network[];
  currency: Currency;
  creditCard: Card;
  cardId: number;
  ibanId: number;
  wallet: Wallet;
  order: Order;
  network: Network;
  status: string;
  type = 'buy';

  constructor(input: any) {
    this.id = input.id;
    this.bankFee = input.bank_fee;
    this.bankFee = Math.round(this.bankFee / 10);
    this.createdAt = input.created_at;
    this.updatedAt = input.updated_at;
    this.networks = input.networks?.map(network => new Network(network)) ?? [];
    if (this.networks.length > 0) {
      this.network = this.networks[0];
    }
    this.currency = new Currency(input.currency);
    this.wallet = new Wallet(input.wallet ?? {});
    if (input.price) {
      this.order = new Order({
        price: input.price,
        amount: input.amount
      });
    }
    this.status = input.status;
    this.type = input.side;
  }
}

export class Network {
  buyNote: string;
  sellNote: string;
  buyTime: string;
  sellTime: string;
  fee: number;
  minConfirmations: number;
  network: string;

  constructor(input: any) {
    this.buyNote = input.buy_note;
    this.sellNote = input.sell_note;
    this.buyTime = input.buy_time;
    this.sellTime = input.sell_time;
    this.fee = +input.fee ?? 0;
    this.minConfirmations = input.min_confirmations;
    this.network = input.network;
  }
}
