<div class="d-flex justify-content-between">
  <app-sidebar></app-sidebar>
  <div class="content m-t-c">
    <div class="container-fluid">
      <p class="fs-20 fw-900 mb-32">پاسخ به سوالات متداول کاربران را در این قسمت مشاهده کنید</p>
      <div dir="ltr" class="card card-gradient px-3">
        <div dir="rtl" class="card-body">
          <ng-container *ngIf="!loading;else skeleton">
            <ng-container *ngIf="category.length > 0">
              <div class="row" *ngFor="let item of category; index as i">
                <div class="col">
                  <button (click)="expand(i)"  class="collapsible btn"><span class="fw-900 fs-24">{{item.title}}</span>
                    <svg class="arrow-icon" [class.spin]="!item.isExpanded" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g transform="translate(24 24) rotate(180)">
                        <g transform="translate(2 2)">
                          <path
                            d="M0,5.916v8.168C0,17.623,2.276,20,5.665,20h8.67C17.724,20,20,17.623,20,14.084V5.916C20,2.378,17.723,0,14.334,0H5.665C2.276,0,0,2.378,0,5.916"
                            fill="#fff" opacity="0.4"/>
                          <path
                            d="M.218,5.687,3.966,9.452a.773.773,0,0,0,1.064,0L8.778,5.687A.751.751,0,0,0,7.714,4.628L5.247,7.105V.75a.75.75,0,0,0-1.5,0V7.105L1.281,4.628A.75.75,0,0,0,.218,5.687"
                            transform="translate(5.502 5.168)" fill="#fff"/>
                        </g>
                      </g>
                    </svg>
                  </button>
                  <div  class="collapse" [id]="'collapseExample_'  + i">
                    <div *ngFor="let question of item.faqs" class="content-collapse">
                      <div class="question-container">
                        <p class="fs-20 fw-900 mt-12 question-title">{{question.name}}</p>
                        <p class="fs-18 fw-bold mb-0" [innerHTML]="question.body | safeHtml"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #skeleton>
            <div class="row">
              <div class="col">
                <button class="collapsible btn">
                  <span class="fw-900 fs-24"><ngx-skeleton-loader class="faq-title" animation="progress-dark"></ngx-skeleton-loader></span>
                </button>
                <div >
                  <div class="content-collapse">
                    <div class="question-container">
                      <p class="fs-20 fw-900 mt-12 question-title">
                        <ngx-skeleton-loader class="faq-content" animation="progress-dark"></ngx-skeleton-loader>

                      </p>
                      <p class="fs-18 fw-bold mb-0">
                        <ngx-skeleton-loader class="faq-content" animation="progress-dark"></ngx-skeleton-loader>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button class="collapsible btn">
                  <span class="fw-900 fs-24"><ngx-skeleton-loader class="faq-title" animation="progress-dark"></ngx-skeleton-loader></span>
                </button>
                <div >
                  <div class="content-collapse">
                    <div class="question-container">
                      <p class="fs-20 fw-900 mt-12 question-title">
                        <ngx-skeleton-loader class="faq-content" animation="progress-dark"></ngx-skeleton-loader>

                      </p>
                      <p class="fs-18 fw-bold mb-0">
                        <ngx-skeleton-loader class="faq-content" animation="progress-dark"></ngx-skeleton-loader>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</div>
