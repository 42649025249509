import {Injectable} from '@angular/core';
import {HttpService} from 'src/app/shared/services/http.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {GapModel} from '../models/gap.model';
import {ForumCategoryModel} from '../models/forum-category.model';

@Injectable({
  providedIn: 'root'
})
export class GapService {

  constructor(private http: HttpService) {
  }

  getListOfGaps(page, length, orderBy, dir, queryParams): Observable<GapModel[]> {
    return this.http.request('get', `/v1/forum?page=${page}&length=${length}&order_by=${orderBy}&dir=${dir}${queryParams}`).pipe(
      map((response: any) => response.data),
      map(data => data.map(gap => new GapModel(gap))));
  }

  likeOrDisLikeForum(id): Observable<GapModel[]> {
    return this.http.request('post', `/v1/forum/${id}/like`, {}).pipe(
      map((response: any) => response.data));
  }

  getForumById(id): Observable<GapModel> {
    return this.http.request('get', `/v1/forum/${id}`).pipe(
      map((response: any) => response.data),
      map(data => new GapModel(data)));
  }

  getForumCategories(): Observable<ForumCategoryModel[]> {
    return this.http.request('get', '/v1/forum-category').pipe(
      map((response: any) => response.data));
  }

  createForum(category: string,
              title: string,
              message: string,
              sendNotificationOnNewReply: boolean): Observable<any> {
    return this.http.request('post', '/v1/forum', {
      category,
      title,
      message,
      send_notification_on_new_reply: sendNotificationOnNewReply
    }).pipe(
      map((response: any) => response.data));
  }

  replyToForum(message: string,
               sendNotificationOnNewReply: boolean,
               replyTo: number): Observable<any> {
    return this.http.request('post', '/v1/forum', {
      message,
      reply_to: replyTo,
      send_notification_on_new_reply: sendNotificationOnNewReply
    }).pipe(
      map((response: any) => response.data));
  }
}
