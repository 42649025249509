import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LivePriceComponent} from './components/live-price/live-price.component';

const routes: Routes = [
  {
    path: 'live-price',
    component: LivePriceComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LivePriceRoutingModule { }
