import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {CoinChartModel} from 'src/app/shared/models/coin.chart.model';
import {ChartOptions} from 'chart.js';
import {CoinService} from 'src/app/shared/services/coin-service';
import {SubSink} from 'subsink';
import {DatePipe, DecimalPipe} from '@angular/common';
import {DateFormatPipe} from 'src/app/shared/pipes/jdate.pipe';
import {FormatNumberPipe} from 'src/app/shared/components/pipes/format-number.pipe';


@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {

  private sub = new SubSink();
  history: CoinChartModel[];
  lineChartType = 'line';
  lineChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    elements: {
      point: {
        radius: 0
      },
    },
    tooltips: {
      rtl: true,
      bodyFontFamily: 'Yekan Bakh',
      titleFontFamily: 'Yekan Bakh',
      callbacks: {
        label: (tooltipItem, data) => {
          const indice = tooltipItem.index;
          return this.formatNumber.transform(this.numberPipe.transform(data.datasets[0].data[indice], '1.0-0'), 'toPersian');
        }
      }
    },
    scales: {
      gridLines: {
        drawOnChartArea: false,
        drawBorder: false
      },
      yAxes: [{
        gridLines: {
          drawBorder: false,
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: false,
          lineHeight: 0,
          padding: -20
        },
      }],
      xAxes: [{
        gridLines: {
          drawBorder: false,
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: false,
          padding: -20,
          fontSize: 0
        },
      }]
    },
  };
  customOptions: OwlOptions = {
    rtl: true,
    nav: false,
    dots: false,
    autoWidth: true,
    responsive: {
      0: {
        items: 1,
      },
      251: {
        items: 1.3,
      },
      502: {
        items: 2.3,
      },
      698: {
        items: 2.7,
      },
      900: {
        items: 3.0,
      },
    }

  };

  constructor(private coinService: CoinService, private dateFormat: DateFormatPipe, private formatNumber: FormatNumberPipe, private numberPipe: DecimalPipe) {
  }

  getHistory(): void {
    this.sub.sink = this.coinService.getCoinsHistory([
      'ADA',
      'BCH',
      'BTC',
      // 'DOGE',
      'EOS',
      'ETH',
      'LTC',
      'SHIB',
      'USDT',
      'XRP',
      'BNB',
      'XLM',
    ]).subscribe((data) => {
      this.history = data;
      for (const coin of this.history) {
        coin.chartData = [
          {
            data: coin.history.map(hist => hist.irrSellPrice),
            borderWidth: 1,
            lineTension: 0,
            fill: 'start',
          }
        ];
        coin.chartLabel = coin.history.map(hist => this.formatNumber.transform(this.dateFormat.transform(hist.date, 'hh:mm YYYY/MM/DD'), 'toPersian'));
        if (coin.current.priceDiff >= 0) {
          coin.lineChartColors = [{
            borderColor: '#7CEB91',
            backgroundColor: '#7CEB910B',
          }];
        } else {
          coin.lineChartColors = [{
            borderColor: '#FF5F5F',
            backgroundColor: '#FF5F5F14',
          }];
        }
      }

    });
  }

  ngOnInit(): void {
    this.getHistory();
  }

}
