<ng-container *ngIf="forums;else skeleton">
  <div *ngFor="let item of forums.slice(0,3);let i=index">
    <div class="discussion">
      <div class="d-flex align-items-center">
        <div class="avatar-holder d-flex align-items-center justify-content-center"
             [style]="'border-color:' + item?.user?.avatar?.color">
          <div class="avatar-circle d-flex justify-content-center align-items-end position-relative"
               [style]="'background:'+item?.user?.avatar?.color">
            <img *ngIf="item?.user?.avatar?.icon" width="65" height="65" [src]="item?.user?.avatar?.icon" alt="avatar">
            <div class="backdrop" [style]="'background:url('+item?.user?.avatar?.icon+')'"></div>
          </div>
        </div>
        <div class="discussion-texts">
          <p class="title">{{item.title}}</p>
          <p class="description">{{item.message}}</p>
        </div>
      </div>
    </div>
    <div class="divider" *ngIf="i!==2"></div>
  </div>

</ng-container>
<ng-template #skeleton>
  <div>
    <div class="discussion">
      <div class="d-flex align-items-center">
        <ngx-skeleton-loader class="faq-avatar" appearance="circle" animation="progress-dark"></ngx-skeleton-loader>
        <div class="discussion-texts">
          <p class="title">
            <ngx-skeleton-loader class="faq-title" animation="progress-dark"></ngx-skeleton-loader>
          </p>
          <p class="description"><ngx-skeleton-loader class="faq-content" animation="progress-dark"></ngx-skeleton-loader></p>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="discussion">
      <div class="d-flex align-items-center">
        <ngx-skeleton-loader class="faq-avatar" appearance="circle" animation="progress-dark"></ngx-skeleton-loader>
        <div class="discussion-texts">
          <p class="title">
            <ngx-skeleton-loader class="faq-title" animation="progress-dark"></ngx-skeleton-loader>
          </p>
          <p class="description"><ngx-skeleton-loader class="faq-content" animation="progress-dark"></ngx-skeleton-loader></p>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="discussion">
      <div class="d-flex align-items-center">
        <ngx-skeleton-loader class="faq-avatar" appearance="circle" animation="progress-dark"></ngx-skeleton-loader>
        <div class="discussion-texts">
          <p class="title">
            <ngx-skeleton-loader class="faq-title" animation="progress-dark"></ngx-skeleton-loader>
          </p>
          <p class="description"><ngx-skeleton-loader class="faq-content" animation="progress-dark"></ngx-skeleton-loader></p>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="d-flex justify-content-center mt-5">
  <button type="button" class="btn btn-primary" [routerLink]="['/gap']">مشاهده همه</button>
</div>
