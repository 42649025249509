import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appNumberLimit]'
})
export class NumberLimitDirective {

  @Input() maxDigit = 9;
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event']) onchange(event: any): void {
    let value = this.el.value;
    value = this.el.value?.toString();
    if (value && value.length > this.maxDigit) {
      this.el.value = (value.substr(0, value.length - 1));
    }
  }
}
